import Vue from 'vue'
import Router from 'vue-router'
import About from '../components/about.vue'
import Esg from '../components/esg.vue'
import Home from '../components/home.vue'
import DataPage from '../components/dataPage.vue'
import DataProduct from '../components/dataProduct.vue'
Vue.use(Router)

export const routes = [
  {
    path: '/about',
    component: About,
    meta: 4
  },
  {
    path: '/esg',
    component: Esg,
    meta: 3
  },
  {
    path: '/home',
    component: Home,
    meta: 1
  },
  {
    path: '/dataPage',
    component: DataPage,
    meta: 2
  },
  {
    path: '/dataProduct',
    component: DataProduct,
    meta: 2
  },
  { path: '/*', redirect: '/home' }
]

export default new Router({
  // mode: 'hash',
  mode: 'history',
  // scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
