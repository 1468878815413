<template>
  <div class="page-data">
    <!-- PC -->
    <div class="page-banner">
      <div class="banner-mask">
        <div class="banner-title">OneESG数据产品</div>
        <div class="banner-subtitle" style="margin-top: 31px">数据推动可持续发展未来</div>
        <div class="banner-desc">数据推动可持续发展未来</div>
        <div class="one-button" @click="applyPageState = true">申请试用</div>
      </div>
    </div>
    <div class="data-product" id="sf1">
      <div class="product-container">
        <div class="product-title">OneESG价值核算数据</div>
        <div class="product-desc">
          OneESG价值核算数据库提供独家ESG价值核算数据，量化反映会员企业对社会可持续性发展的贡献，识别可持续发展风险及机遇。提供企业ESG净值、ESG风险机遇值、ESG市盈率等数据。
        </div>
        <div class="product-tips">
          <div class="tips-left">
            <div class="tips-icon"><img src="../assets/t1212.png" alt=""></div>
            <div class="tips-box">
              <div class="tip-tex">核算范围</div>
              <div class="tip-desc">全部 A 股上市公司和香港上市内地公司</div>
            </div>
          </div>
          <div class="tips-right">
            <div class="tips-icon"><img src="../assets/t123.png" alt=""></div>
            <div class="tips-box">
              <div class="tip-tex">历史长度</div>
              <div class="tip-desc">2017年起</div>
            </div>
          </div>
        </div>
        <div class="product-im">
          <img src="../assets/adf.png" alt="">
        </div>
      </div>
    </div>
    <div class="data-product" style="background: #f5f6f9" id="sf2">
      <div class="product-container">
        <div class="product-title">OneESG底层数据</div>
        <div class="product-desc">
          提供多年海量结构化企业披露ESG数据及独家ESG数据缺省补全数据，涵盖环境管理、降污减排、气候变化、资源使用、生物多样性、ESG治理、公司治理等各个层面。
        </div>
        <div class="product-tips">
          <div class="tips-left">
            <div class="tips-icon"><img src="../assets/t1212.png" alt=""></div>
            <div class="tips-box">
              <div class="tip-tex">核算范围</div>
              <div class="tip-desc">全部 A 股上市公司和香港上市内地公司</div>
            </div>
          </div>
          <div class="tips-right">
            <div class="tips-icon"><img src="../assets/t123.png" alt=""></div>
            <div class="tips-box">
              <div class="tip-tex">历史长度</div>
              <div class="tip-desc">2017年起</div>
            </div>
          </div>
        </div>
        <div class="product-im">
          <img src="../assets/adddv.png" alt="">
        </div>
      </div>
    </div>
    <div class="data-product">
      <div class="product-container">
        <div class="product-title">应用场景</div>
        <div class="scene">
          <div class="scene-item">
            <div class="item-top">
              <img src="../assets/a186.png" alt="" />
            </div>
            <div class="item-title">ESG金融研究</div>
            <div class="item-desc">
              帮助金融分析师和高校老师研究以环境、社会和治理因素为核心的金融理论和实践，探索ESG因素与企业财务表现相关性，将ESG因素纳入投资决策
            </div>
          </div>
          <div class="scene-item">
            <div class="item-top">
              <img src="../assets/a187.png" alt="" />
            </div>
            <div class="item-title">投资组合构建</div>
            <div class="item-desc">ESG学术研究课题价值攀升，ESG跨学科研究领域广，为研究者提供高质量ESG数据库。</div>
          </div>
          <div class="scene-item">
            <div class="item-top">
              <img src="../assets/a188.png" alt="" />
            </div>
            <div class="item-title">ESG模型和评级</div>
            <div class="item-desc">
              协助金融机构和大型企业集团搭建ESG数据模型和评级体系，识别客户和供应商ESG风险，提升ESG竞争力
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动 -->
    <div class="page-moblie">
      <div class="page-m-title">OneESG数据产品</div>
      <div class="page-m-desc">数据推动可持续发展未来</div>
      <div class="one-button" @click="applyPageState = true">申请试用</div>
    </div>
    <div class="page-moblie-data">
      <div class="moblie-data-title">OneESG价值核算数据</div>
      <div class="moblie-data-desc">
        OneESG价值核算数据库提供独家ESG价值核算数据，量化反映会员企业对社会可持续性发展的贡献，识别可持续发展风险及机遇。提供企业ESG净值、ESG风险机遇值、ESG市盈率等数据。
      </div>
      <div class="moblie-box-icon">
        <div class="box-icon-left"><img src="../assets/t1212.png" alt=""></div>
        <div class="box-icon-right">
          <div class="box-tip-top">核算范围</div>
          <div class="box-tip-top-desc">全部A股上市公司和香港上市内地公司</div>
        </div>
      </div>
      <div class="moblie-box-icon">
        <div class="box-icon-left"><img src="../assets/t123.png" alt=""></div>
        <div class="box-icon-right">
          <div class="box-tip-top">历史长度</div>
          <div class="box-tip-top-desc">2017年起</div>
        </div>
      </div>
      <div class="mobolie-img">
        <img src="../assets/mn1.png" alt="">
      </div>
    </div>
    <div class="page-moblie-data">
      <div class="moblie-data-title">OneESG底层数据</div>
      <div class="moblie-data-desc">
        提供多年海量结构化企业披露ESG数据及独家ESG数据缺省补全数据，涵盖环境管理、降污减排、气候变化、资源使用、生物多样性、ESG治理、公司治理等各个层面。
      </div>
      <div class="moblie-box-icon">
        <div class="box-icon-left"><img src="../assets/t1212.png" alt=""></div>
        <div class="box-icon-right">
          <div class="box-tip-top">核算范围</div>
          <div class="box-tip-top-desc">全部A股上市公司和香港上市内地公司</div>
        </div>
      </div>
      <div class="moblie-box-icon">
        <div class="box-icon-left"><img src="../assets/t123.png" alt=""></div>
        <div class="box-icon-right">
          <div class="box-tip-top">历史长度</div>
          <div class="box-tip-top-desc">2017年起</div>
        </div>
      </div>
      <div class="mobolie-img">
        <img src="../assets/mn3.png" alt="">
      </div>
    </div>
    <div class="application-box-aa">
      <div class="application-moblie-title">应用场景</div>
      <div class="application-moblie-item">
        <div class="application-moblie-left"><img src="../assets/a186.png" alt=""></div>
        <div class="application-moblie-right">
          <div class="application-moblie-top">ESG金融研究</div>
          <div class="application-moblie-desc"> 帮助金融分析师和高校老师研究以环境、社会和治理因素为核心的金融理论和实践，探索ESG因素与企业财务表现相关性，将ESG因素纳入投资决策
          </div>
        </div>
      </div>
      <div class="application-moblie-item">
        <div class="application-moblie-left"><img src="../assets/a187.png" alt=""></div>
        <div class="application-moblie-right">
          <div class="application-moblie-top">投资组合构建</div>
          <div class="application-moblie-desc"> ESG学术研究课题价值攀升，ESG跨学科研究领域广，为研究者提供高质量ESG数据库
          </div>
        </div>
      </div>
      <div class="application-moblie-item">
        <div class="application-moblie-left"><img src="../assets/a188.png" alt=""></div>
        <div class="application-moblie-right">
          <div class="application-moblie-top">ESG模型和评级</div>
          <div class="application-moblie-desc"> 协助金融机构和大型企业集团搭建ESG数据模型和评级体系，识别客户和供应商ESG风险，提升ESG竞争力
          </div>
        </div>
      </div>
    </div>
    <ContactUS></ContactUS>
    <ApplyPage v-if="applyPageState"></ApplyPage>
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import PageFooter from './footer';
import ContactUS from './contactUs.vue';
import ApplyPage from './apply.vue';
import {
  EventBus
} from "./event.js";
export default {
  name: 'DataProduct',
  components: { PageFooter, ContactUS, ApplyPage },
  props: {},
  data() {
    return {
      applyPageState: false,
    };
  },

  async mounted() {
    EventBus.$on("aMsg", (msg) => {
      var SC = document.getElementById(this.getQueryParam(msg, 'sf'));
      if (SC) {
        SC.scrollIntoView({ behavior: 'smooth' });
      }
    });
    var element = document.getElementById(this.$route.query.sf);
    console.log(element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  },
  watch: {
    applyPageState(val) {
      if (val) {
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.getElementById('app').style.overflow = 'auto';
      }
    }
  }
  ,
  methods: {
    getQueryParam(url, param) {
      const regex = new RegExp('[?&]' + param + '=([^&#]*)');
      const results = regex.exec(url);
      return results ? decodeURIComponent(results[1]) : null;
    }

  },
};
</script>

<style scoped lang="stylus">
.page-banner{
  width: 100%;
  height: 400px;
  background: url('../assets/蒙版@2x.png') center center / 100% 400px no-repeat;
  background-size: cover;

}

.banner-title{
  font-size: 34px;
  color: #2b3440;font-weight: bold;
  padding-top: 110px;
}
.banner-desc{
  color: #2b3440;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  text-align: justify;
  margin-top: 25px;
}.one-button{
  width: 210PX;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #F5BA0D;
color: #fff;
cursor: pointer;
font-size: 16px;
border-radius: 8px;
margin-top: 27px;
}
.banner-mask{
  width: 1200px;
  height: 100%;
  box-sizing: border-box;
   margin 0 auto
}
.banner-subtitle{
  font-size: 18px;
  color: #2b3440;
  line-height: 30px;
}

.data-product{
  width: 100%;
  background: #fff;
  padding-bottom:60px;
}

.product-container{
  width: 1200px;
  margin: 0 auto;
}
.product-title{
  text-align: center;
  font-size: 40px;
  color: #1B2532;
  font-weight: bold;
  padding-top: 60px;
}

.product-desc{
  font-size: 18PX;
  color: #2B3440
  line-height: 30px;
  text-align: center;
  margin-top: 30px;
}

.product-im{
  width 1200px
  margin-top 60px
  img{
    width: 100%;
  }
}
.scene{
  margin-top: 74px;
  padding-bottom: 139px;
  display: flex;
  justify-content: space-between;
}
.scene-item{
  background: #F4F5F7;
  width: 380px;
  height: 460px;
  box-sizing border-box
  padding 30px
}

.item-top{
  width: 110px;
  height: 100px;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.item-title{
  font-weight: bold;
  font-size: 28px;
  color: #0ECB92;
  margin-top 60px
  text-align center
}
.item-desc{
  margin-top 30px
  font-weight: 400;
  font-size: 20px;
  color: #738093;
  text-align center
  line-height: 34px;
}
@media only screen and (max-width: 768px) {


  .page-banner{
    display none
  }
  .data-product{
    display none
  }
}
.product-tips{
  width 1200px
  height  120px
  margin-top 60px
  background: rgba(15,203,146,0.1);
  display flex
  box-sizing border-box
  padding-top 26px
  padding-left 66px
  justify-content space-between
  padding-right 123px
}
.tips-left{
  display flex
}
@media screen and (min-width: 1024px) {
.page-moblie{
  display: none;
}
.banner-desc{
  display: none;
}
.data-product-mobile{
  display: none;
}
.page-moblie-data{
  display: none;
}
.application-box-aa{
  display none
}
}

.tips-icon{
  width 60px
  height 67px
  img{
    width 100%
    height 100%
  }
}

.tips-box{
  margin-left 20px
}
.tip-tex{
  font-weight: 400;
font-size: 18px;
color: #2B3440;
line-height: 18px;
text-align: left;
}
.tip-desc{
  margin-top 12px
  font-weight: bold;
font-size: 30px;
color: #0ECB92;
line-height: 45px;
text-align: left;
}

.tips-right{
  display flex
}
.page-moblie{
  box-sizing border-box
  width 100%
  height 312px
  background: url('../assets/mp2.png') center center  no-repeat;
  background-size cover
  padding-left 14px
  padding-right 14px
  }
  
  .page-m-title{
  font-weight: bold;
  font-size: 28px;
  color: #2B3440;
  text-align:justify;
  padding-top 84px
  }
  
  .page-m-desc{
  width calc(100% - 14px -14px)
  font-weight: 400;
  font-size: 14px;
  text-align:justify;
  color: #2B3440;line-height: 24px;
  } 
.page-moblie-data{
  padding 0 14px
  padding-top 30px
  box-sizing border-box

}
.moblie-data-title{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 21px;
  color: #1B2532;
  line-height: 21px;
  text-align: center;
  font-style: normal;
}

.moblie-data-desc{
  margin-top 14px
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 14px;
color: #2B3440;
line-height: 20px;
text-align: center;
}
.moblie-box-icon{
  display flex
  padding 9px  14px
  margin-top 14px
  box-sizing border-box
}

.box-icon-left{
  display flex
  align-items center
  img{
    width 30px
  height 30px
  }
}

.box-tip-top{
  font-size 14px
  color #000
}

.box-tip-top-desc{
  font-size 18PX
  font-weight bold
  color: #0ECB92;
}

.moblie-box-icon{
  width 100%
min-height: 42px;
background: rgba(15,203,146,0.05);
}
.box-icon-right{
  margin-left 10px
}

.mobolie-img{
  padding-top:30px;
  width:100%;
   img{
    width:100%
   }
}

.application-moblie-title{
  font-weight: bold;
font-size: 21px;
color: #1B2532;
text-align center
padding-top 28px
padding-bottom 28px
}
.application-moblie-item{
  display flex
  min-height: 107px;
background: #F4F5F7;
box-sizing border-box
padding 20px
margin-top 14px
}

.application-moblie-left{
  display flex
  align-items center
  img{
    width 38px
    height 35px
  }
}
.application-box-aa{
  min-height 400px
  padding 0 14px
  box-sizing border-box
  padding-bottom 30px
}

.application-moblie-top{
  font-weight: bold;
font-size: 14px;
color: #0ECB92;
}

.application-moblie-desc{
  font-weight: 400;
font-size: 12px;
color: #738093;
}

.application-moblie-right{
  margin-left 14px
}


</style>
