<template>
  <div class="page-header">
    <div class="login-button" @click="openView">登录</div>
    <div class="page-header-content">
      <!--  -->
      <div class="head-logo">
        <img :src="require('@/assets/logo @2x.png')" alt="" />
      </div>
      <div class="page-head-tabs">
        <div class="tab-list">
          <div class="tab-item" @click="handleSwitchTab(s.value)" v-for="s in tabList" :key="s.value"
            :class="{ active: currTab === s.value }" :ref="s.value" @mouseover="() => {
      handleMouseOver(s.value);
    }">
            <span> {{ s.label }}</span>
            <span class="ssss-d" v-if="s.value == 2"><i
                :class="[s.value == 2 && showState ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i></span>
            <div class="product-box-a1" v-if="s.value == 2 && showState" @mouseleave="() => {
      handleMouseLeave(s.value);
    }">
              <div class="product-left">
                <div class="left-item" v-for="(item, index) in dataList" :key="index" @click="handlePath(item.path)">
                  <span v-if="index == 0" class="onee-item"> {{ item.label }} <i class="el-icon-arrow-right"></i></span>
                  <span v-else class="twos-item">{{ item.label }}</span>
                </div>
              </div>
              <div class="product-right">
                <div class="left-item" v-for="(item, index) in dataValList" :key="index" @click="handlePath(item.path)">
                  <span v-if="index == 0" class="onee-item"> {{ item.label }} <i class="el-icon-arrow-right"></i></span>
                  <span v-else class="twos-item">{{ item.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="page-header-icon" @click="handleCheck"><i class="el-icon-s-fold"></i>
      <img :src="require('@/assets/logo @2x.png')" alt="" class="img-logo" />
    </div>
    <div class="sider" v-if="maskState">
      <div class="sider-container">
        <div class="sider-head">
          <div class="sider-head-warp">
            <div class="side-left">
              <img :src="require('@/assets/logo @2x.png')" alt="" />
            </div>
            <div class="side-right" @click="closeMask">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="sider-line"></div>
        </div>
        <div class="sider-head" @click="handlePath('/home')">
          <div class="sider-head-warp">
            <div class="side-left">
              <span class="left-title"> 首页</span>
            </div>
            <div class="side-right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="sider-line"></div>
        </div>
        <div class="sider-head " @click="palyOpen" :class="{ 'expanded': isOpen }">
          <div class="sider-head-warpw">
            <div class="side-left">
              <span class="left-title"> 产品</span>
            </div>
            <div class="side-right">
              <i :class="{ 'el-icon-arrow-right': !isOpen, 'el-icon-arrow-down': isOpen }"></i>
            </div>
          </div>
          <div class="sider-line"></div>
          <div class="sider-head-warp" @click.stop="handlePath('/dataProduct')">
            <div class="side-left">
              <span class="left-title">OneESG数据产品</span>
            </div>
            <div class="side-right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="sider-line"></div>
          <div class="sider-head-warp " @click.stop="handlePath('/dataPage')">
            <div class="side-left">
              <span class="left-title">OneESG数字化平台</span>
            </div>
            <div class="side-right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="sider-line"></div>
        </div>

        <div class="sider-head" @click="handlePath('/esg')">
          <div class="sider-head-warp">
            <div class="side-left">
              <span class="left-title"> ESG洞察</span>
            </div>
            <div class="side-right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="sider-line"></div>
        </div>
        <div class="sider-head" @click="handlePath('/about')">
          <div class="sider-head-warp">
            <div class="side-left">
              <span class="left-title">关于我们</span>
            </div>
            <div class="side-right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="sider-line"></div>
        </div>
        <div class="sider-head" @click="handleSS">
          <div class="sider-head-warp">
            <div class="side-left">
              <span class="left-title">申请试用</span>
            </div>
            <div class="side-right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="sider-line"></div>
        </div>
        <!-- <div class="list-items" v-for="(item, index) in sideList" :key="index">
          <div v-if="item.label != '产品' && item.label != '申请试用'" class="pb">
            <router-link :to="item.value" class="router-link">{{ item.label }}</router-link>
          </div>
          <div v-else-if="item.label == '申请试用'" class="name" @click="handleSS">
            {{ item.label }}
          </div>
          <div v-else>
            <div class="name">
              <div class="asssx">产品</div>
              <div class="asssx" style="margin-left: 10px" @click="handlePath('/dataProduct')">OneESG数据产品</div>
              <div class="asssx" style="margin-left: 10px" @click="handlePath('/dataPage')">OneESG数字化平台</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  EventBus
} from "./event.js";
export default {
  name: 'HeaderPage',
  props: {
    msg: String,
  },
  data() {
    return {
      isOpen: false,
      applyPageState: false,
      showState: false,
      maskState: false,
      activeLeft: 0,
      activeWidth: 30,
      tabWidth: 76,
      dataList: [
        {
          label: 'OneESG数据产品',
          path: '/dataProduct?sf=sf2sss',
        },
        {
          label: 'ESG价值核算数据',
          path: '/dataProduct?sf=sf1',
        },
        {
          label: 'ESG价值底层数据',
          path: '/dataProduct?sf=sf2',
        },
      ],
      dataValList: [
        {
          label: 'OneESG数字化平台',
          path: '/dataPage?se=aqs',
        },
        {
          label: 'OneESG价值核算数据平台',
          path: '/dataPage?se=aq1',

        },
        {
          label: 'OneESG碳智平台',
          path: '/dataPage?se=aq2',
        },
        {
          label: 'OneESG智能数据披露平台',
          path: '/dataPage?se=aq3',
        },
      ],

      sideList: [
        { label: '首页', value: '/home' },
        {
          label: '产品',
          list: [
            { label: 'OneESG数据产品', value: 2 },
            { label: 'OneESG数字化平台', value: 2 },
          ],
        },
        { label: 'ESG洞察', value: '/esg' },
        { label: '关于我们', value: '/about' },
        { label: '申请试用', value: 4 },
      ],
      tabList: [
        { label: '首页', value: 1 },
        { label: '产品', value: 2 },
        { label: 'ESG洞察', value: 3 },
        { label: '关于我们', value: 4 },
      ],
      currTab: 1,
    };
  },

  mounted() {

    document.addEventListener('click', this.handleClickOutside);
    this.currTab = this.$route.meta;
    this.$nextTick(() => {
      this.activeLeft = this.$refs[this.currTab][0]?.offsetLeft;
      this.activeWidth = this.$refs[this.currTab][0]?.offsetWidth - this.tabWidth;
    });
  },

  methods: {
    closeMask() {
      this.isOpen = false;
      this.maskState = false
      document.getElementById('app').style.overflow = 'auto';
    },
    palyOpen() {
      this.isOpen = !this.isOpen
    },
    openView() {
      // window.open('http://app.uat.oneesg.cn');

      window.open('https://app.oneesg.cn');

    },
    handleSS() {
      document.getElementById('app').style.overflow = 'auto';
      this.maskState = false;
      this.$parent.applyPageState = true
    },
    handlePath(path) {
      this.currTab = 2
      this.isOpen = false
      EventBus.$emit("aMsg", path);
      this.maskState = false
      document.getElementById('app').style.overflow = 'auto';
      this.$router.replace(path);
      this.showState = false;

    },
    handleCheck() {
      this.maskState = !this.maskState;
      if (this.maskState) {
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.getElementById('app').style.overflow = 'auto';
      }
    },
    handleSwitchTab(val) {
      if (val == 2) {
        return;
      }
      this.$emit('headerTabChange', val);
      this.currTab = val;
      this.$nextTick().then(() => {
        this.activeLeft = this.$refs[val][0]?.offsetLeft;
        this.activeWidth = this.$refs[val][0]?.offsetWidth - this.tabWidth;
      });
    },
    handleMouseOver(val) {
      if (val == 2) {
        this.showState = true;
      }
    },
    handleClickOutside(event) {
      const tabItems = this.$refs['2'] || [];
      const clickedInside = tabItems.some(el => el.contains(event.target));
      if (!clickedInside) {
        this.showState = false;
      }
    },
    handleMouseLeave() {
      this.showState = false;
    },
  },

  computed: {
    activeTabStyle() {
      return `left: ${this.activeLeft}px; transform: translateX(${this.activeWidth / 2}px);`;
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped lang="stylus">
.page-header {
  width 100%;
  height: 64px;
  box-sizing:border-box;
  background: rgba(255,255,255,0.7);
  backdrop-filter: blur(15px);
  position relative
  z-index 200
}
.login-button{
  position absolute
  width: 80px;
  height: 64px;
  background: #FABE0D;
  right 0
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  font-family: PingFangSC, PingFang SC;
  cursor pointer
  text-align center
  line-height 64px
  font-style: normal;
}

.tabs {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;

  &__item {
    font-size: 16px;
    font-weight: bold;
    color: #2B3440;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 60px;
    cursor: pointer;
    width: 74px;
    height: 100%;

    font-family: PingFangSC-Medium, PingFang SC;
  }

  &__item:hover {
    /* color: #416DF9; */
  }

}

.active{
  color: #0ECB92 !important;
  position relative
}
.active::after{
  content :"";
  width: 20px;
height: 4px;
background: #0ECB92;
position absolute
bottom -20px;
    left: 50%;
    margin-left: -10px;
}
@media screen and (min-width: 1024px) {
  .page-header-icon{
 display: none;
}
.sider{
  display: none !important
}
}
@media only screen and (max-width: 768px) {
    /* 在这里添加针对小屏幕设备的样式 */
 
  .page-header{
    padding: 0;
    height: 45px;
    padding-left: 14px;
    padding-right: 14px;
  }
.login-button{
  display none
}
  .page-header__logo{ 
    width: 75px;
    height: 24px;
  }
  .page-header-content{
    display none !important
  }
}

.page-header-icon{
  color: #333;
  font-size: 18px;
  cursor: pointer;
  position relative
  padding-top 14px
}
.sider{
  width: 100vw;
  display: block;
  height: 100vh ;
  background: rgba(0, 9, 0, .3);
  position: fixed;left: 0;
  z-index: 1111;

  top: 0;
}
.sider-container{

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  float: right;
}

.router-link{
  color: #1B2532 !important;
  text-decoration: none;
  font-family: SourceHanSansCN, SourceHanSansCN;
  margin-bottom: 10px;
}
.pb{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-size: 14px;
  color: #1B2532 !important;
  margin-bottom: 10px;
}

.name{
  font-size: 14px;
  color: #1b2532;
  margin-bottom: 10px;
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.product{
  width: 520px;
  height: 200px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  box-sizing: border-box;
  z-index: 2500  !important
  top: 50px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  padding-top:40px;

}

.product-box-a1{
  width: 520px;
  height: 200px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  z-index 10000
  top: 50px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  padding-top:40px;
}


.left-item{
  font-weight: normal;
  color: #000000;
line-height: 14px;
  font-size: 14px;
  text-align: left;
}

.page-header-content{
  width:1200px;
  margin :0 auto;
  
  height:64px;
  display:flex;
  align-items:center;
}

.head-logo{
  width :100px;
  height:34px;
  img{
    width:100%;
    height :100%;
  }
}

.onee-item{
  font-weight: bold;
font-size: 18px;
color: #0ECB92;
line-height: 18px;
text-align: left;
  display block
  padding-bottom 8px
border-bottom 3px solid #0ECB92
}

.twos-item{
  display block
  margin-top 20px
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.img-logo{
  position absolute
  width: 61px;
  height: 21px;
  left 50%
  transform: translateX(-50%);
}

.page-head-tabs{
  
}

.tab-item{
  float left
  margin-left 60px
  cursor pointer
  font-weight: bold;
  font-size: 16px;
 color: #2B3440;
 position relative
 z-index 99999
}
>>>.el-carousel__container{
z-index 200 
}

.ssss-d{
  position:absolute;
  right: -27px;
  top: 3px;
  color #000
}
.asssx{
  font-size: 14px;
color: #1B2532;
font-family: SourceHanSansCN, SourceHanSansCN;
}

.sider-head{
  width 100%;
  box-sizing border-box;
  height 60px
  overflow hidden
  transition: height 0.3s ease;
}
.sider-head-warp,.sider-head-warpw{
  width 100%
  height 59px
  display flex
  box-sizing border-box
  justify-content: space-between;
  padding: 0 14px
  align-items center
}

.sider-line{
  width 100%;
  height 0.5px;
background #e5e7eb
}

.side-left{
  width 200px
  img{
    width 100px
    height 34px
  }
}

.el-icon-close,.el-icon-arrow-right,.el-icon-arrow-down{
  font-weight bold
  font-size 21px
}

.left-title{
  font-size 18px
  color #000
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.sider-head.expanded {
  height: 180px; /* 展开后的高度，根据需要调整 */
}

.side-right{
  width 50px
  text-align right 
}
</style>
