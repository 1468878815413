<template>
  <div class="div-box">
    <div class="contact-us">
      <div class="us-content">
        <div class="us-left">
          <div class="us-titlte">联系我们</div>
          <div class="us-desc">合作联系，账号开通，获取OneESG更多信息并试用</div>
        </div>
        <div class="us-right">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
            <div class="el-div">
              <el-form-item label="" prop="name">
                <el-input v-model="ruleForm.name" maxlength="10" class="el-inpiy-diy" placeholder='您的姓名'></el-input>
              </el-form-item>
              <el-form-item label="" prop="companyName">
                <el-input v-model="ruleForm.companyName" maxlength="30" class="el-inpiy-diy"
                  placeholder='企业名称'></el-input>
              </el-form-item>
            </div>
            <div class="el-div">
              <el-form-item label="" prop="email">
                <el-input v-model="ruleForm.email" class="el-inpiy-diy" placeholder='公司邮箱'></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-select class="el-inpiy-diy" collapse-tags v-model="value" multiple placeholder="您希望了解"
                  style="width: 100%">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="el-div">
              <el-form-item label="" prop="mobile" class="el-inpiy-diy">
                <div class="get-div">
                  <el-input v-model="ruleForm.mobile" maxlength="11" placeholder="手机号"></el-input>
                  <el-button @click="handleSendSms" class="a-btn" :disabled="smsBtnDisabled">{{ smsCodeText }}
                  </el-button>
                </div>


              </el-form-item>
              <el-form-item label="" prop="smsCode">
                <el-input v-model="ruleForm.smsCode" class="el-inpiy-diy" placeholder="验证码"></el-input>
              </el-form-item>
            </div>

            <el-form-item label="">
              <el-input type="textarea" v-model="ruleForm.leaveMessage" maxlength="100" placeholder="留言"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="submit" :disabled="loginDisWeb" @click="submitMessage">提交</el-button>
            </el-form-item>
          </el-form>
          <div class="moblie-desc-tip">
            <span style="font-size: 12px;color: #000000;"> 已阅读并同意</span>
            <span style="font-size: 12px;color: #FABE0D;cursor: pointer;margin-left:10px"
              @click='opens'>OneESG用户协议与隐私条款</span>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us-moblie">
      <div class="contact-us-moblie-title">联系我们</div>
      <div class="contact-us-moblie-desc">联系我们，了解有关OneESG的更多信息并试用</div>
      <div class="moblie-form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="0px" class="demo-ruleForm">
          <el-form-item label="" prop="name">
            <div class="lable-molbie">您的姓名 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.name" maxlength="10" class="el-inpiy-diy" placeholder='您的姓名'></el-input>
          </el-form-item>
          <el-form-item label="" prop="companyName">
            <div class="lable-molbie">企业名称 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.companyName" maxlength="30" class="el-inpiy-diy" placeholder='企业名称'></el-input>
          </el-form-item>

          <el-form-item label="" prop="email">
            <div class="lable-molbie">公司邮箱 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.email" class="el-inpiy-diy" placeholder='公司邮箱'></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="lable-molbie">您希望了解 </div>
            <el-select collapse-tags class="el-inpiy-diy" v-model="value" multiple placeholder="您希望了解"
              style="width: 100%">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="" prop="mobile" class="el-inpiy-diy">
            <div class="lable-molbie">手机号 <span style='color:red'>*</span></div>
            <div class="get-div">
              <el-input v-model="ruleForm.mobile" maxlength="11" placeholder="手机号"></el-input>
              <el-button @click="handleSendSms" class="a-btn" :disabled="smsBtnDisabled">{{ smsCodeText }}
              </el-button>
            </div>


          </el-form-item>
          <el-form-item label="" prop="smsCode">
            <div class="lable-molbie">验证码<span style='color:red'>*</span> </div>
            <el-input v-model="ruleForm.smsCode" class="el-inpiy-diy" placeholder="验证码"></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="lable-molbie">留言 </div>
            <el-input type="textarea" v-model="ruleForm.leaveMessage" maxlength="100" placeholder="留言"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="submit" :disabled="loginDisH5" @click="submitMessage1">提交</el-button>
          </el-form-item>
        </el-form>
        <div class="moblie-desc-tip">
          <span style="font-size: 12px;color: #000000;"> 已阅读并同意</span>
          <span style="font-size: 12px;color: #FABE0D;cursor: pointer;margin-left:10px"
            @click='opens'>OneESG用户协议与隐私条款</span>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { commit, sendSms } from '../request/fetch.js';

export default {
  name: 'ContactUS',
  props: {},
  data() {
    var validatePass = (rule, value, callback) => {
      var reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!reg.test(value)) {
        callback(new Error('输入内容必须为邮箱格式'));
      } else {
        callback();
      }
    };
    return {
      loginDisWeb: false,
      loginDisH5: false,
      ruleForm: {
        name: '',
        companyName: '',
        mobile: '',
        smsCode: '',
        email: '',
        hopeToLearnMore: '',
        leaveMessage: '',
        smsUuid: '',
      },

      smsNum: 60,
      smsBtnDisabled: false,
      value: [],
      options: [
        {
          value: 'OneESG价值核算数据',
          label: 'OneESG价值核算数据',
        },
        {
          value: 'OneESG底层数据',
          label: 'OneESG底层数据',
        },
        {
          value: 'OneESG价值核算数据平台',
          label: 'OneESG价值核算数据平台',
        },
        {
          value: 'OneESG碳智平台',
          label: 'OneESG碳智平台',
        },
        {
          value: 'OneESG智能数据披露平台',
          label: 'OneESG智能数据披露平台',
        },
      ],
      rules: {
        name: [{ required: true, message: '输入字符必须为10个以内，包括中英文，不可包括符号', min: 1, max: 10 }],
        companyName: [{ required: true, message: '输入内容必须为30个字符以内，包括中英文和符号' }],
        mobile: [{ required: true, message: '输入内容必须为11位手机号格式', min: 11, max: 11 }],
        email: [{ required: true, message: '输入内容必须为邮箱格式', validator: validatePass }],
        smsCode: [{ required: true, message: '请输入手机验证码' }],
      },
    };
  },
  computed: {
    smsCodeText: function () {
      return this.smsNum === 60 ? `获取验证码` : `${this.smsNum}s后重新发送`;
    },
  },
  async mounted() { },

  methods: {
    opens() {
      let origin = window.origin
      window.open(origin + '/agreement/OneESG用户协议与隐私条款.pdf')
    },
    async handleSendSms() {
      const ruleReg = /^\d{11}$/;
      if (!ruleReg.test(this.ruleForm.mobile)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      let res = await sendSms({ mobile: this.ruleForm.mobile });
      if (res?.code === 0) {
        this.$notify({
          title: '验证码获取成功',
          position: 'top-right',
          type: 'warning',
        });
        this.ruleForm.smsUuid = res.data.smsUuid;
        this.handleGetSms();

      } else if (res?.code === -1) {
        this.$notify({
          title: '获取验证码次数超出今日限制，请明天再试',
          position: 'top-right',
          type: 'warning',
        });
      }
    },
    handleGetSms: async function () {
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (ruleReg.test(this.ruleForm.mobile) && !this.smsBtnDisabled) {
        this.smsBtnDisabled = true;
        let timer = setInterval(() => {
          --this.smsNum;
        }, 1000);
        setInterval(() => {
          clearInterval(timer);
          this.smsNum = 60;
          this.smsBtnDisabled = false;
        }, 60000);
      }
    },
    submitMessage1() {
      this.loginDisH5 = true
      this.$refs['ruleForm1'].validate(async (valid) => {
        if (valid) {
          let str = this.value.join();
          this.ruleForm.hopeToLearnMore = str;
          let res = await commit(this.ruleForm);
          if (res.code == 0) {
            this.loginDisH5 = false
            this.ruleForm = {
              name: '',
              companyName: '',
              mobile: '',
              smsCode: '',
              email: '',
              hopeToLearnMore: '',
              leaveMessage: '',
              smsUuid: '',
            };
            this.value = [];
            this.$refs['ruleForm1'].resetFields();
            // this.$notify({
            //   title: "发送成功",
            //   position: 'top-right',
            //   type: 'warning',
            // });
          } else {
            this.loginDisH5 = false
            if (res?.message || res?.msg) {
              this.$notify({
                title: res?.message || res?.msg,
                position: 'top-right',
                type: 'warning',
              });
            }
          }

        } else {
          this.loginDisH5 = false
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitMessage() {
      this.loginDisWeb = true
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let str = this.value.join();
          this.ruleForm.hopeToLearnMore = str;
          let res = await commit(this.ruleForm);
          if (res.code == 0) {
            this.loginDisWeb = false
            this.ruleForm = {
              name: '',
              companyName: '',
              mobile: '',
              smsCode: '',
              email: '',
              hopeToLearnMore: '',
              leaveMessage: '',
              smsUuid: '',
            };
            this.value = [];
            this.$notify({
              title: "发送成功",
              position: 'top-right',
              type: 'warning',
            });
            this.$refs['ruleForm'].resetFields();
          } else {
            this.loginDisWeb = false
            if (res?.message || res?.msg) {
              this.$notify({
                title: res?.message || res?.msg,
                position: 'top-right',
                type: 'warning',
              });
            }
          }


        } else {
          this.loginDisWeb = false
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus">
  .contact-us{
  width: 100%;
  height: 500px;
  background: url('../assets/count.png')  no-repeat;
  background-size cover
}

.us-content{
  width: 1200px;
  margin:  0 auto;
  display: flex;
  justify-content: space-between;
}

.us-titlte{
  font-size: 40PX;
  color: #000;

  font-weight: bold;
}
.us-left{
  height 500px
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.us-right{
  width:620PX;
  padding-top 57px
}
.us-desc{
  cfont-size: 16px;
  color: #000000;
  margin-top: 15px;
}
.submit{
  width: 100%;
  background: #F5BA0D;
  border-color: #F5BA0D;
}

.el-inpiy-diy{
  >>>.el-input__inner{
    width 300px !important
    height 50px !important
    font-family: SourceHanSansCN, SourceHanSansCN;
    border none
  }
  >>>.el-input__inner::placeholder{
    font-size: 16px !important
    color: #1B2532 !important
    font-family: SourceHanSansCN, SourceHanSansCN;
    line-height: 24px !important
    text-align: left !important
  }
}


>>>.el-textarea__inner::placeholder{
  font-size: 16px !important
      font-family: SourceHanSansCN, SourceHanSansCN;

  color: #1B2532 !important
}
@media only screen and (max-width: 768px) {
  .contact-us{
    display: none;
  }
  >>>.el-form-item {
    margin-bottom: 8px;
}
  >>>.el-form-item{
    margin-bottom 10px !important
  }
  .el-inpiy-diy{
    >>>.el-input__inner{
      width 100% !important
      height 50px !important
      font-family: SourceHanSansCN, SourceHanSansCN;
      border none
    }
    >>>.el-input__inner::placeholder{
      font-size: 16px !important
      color: #1B2532 !important
      font-family: SourceHanSansCN, SourceHanSansCN;
      line-height: 24px !important
      text-align: left !important
    }
  }
}
>>>.el-textarea__inner {
  border: none;
  font-family: SourceHanSansCN, SourceHanSansCN;
  height: 100px;
}

.el-div{
  display flex
  justify-content: space-between
}

.get-div{
  position relative
}
.a-btn{
  position absolute
  right  6px
  top 6px
  width: 112px;
  height: 40px;
  background: #FABE0D;
  border-radius: 6px;
  border:none
  font-weight: 500;
font-size: 16px;
color: #FFFFFF;
  display flex
  align-items center
  justify-content center
}

.contact-us-moblie{
  width 100%
  height: auto;
  background: url('../assets/vvvve.png') center center no-repeat;
  background-size cover
}
.contact-us-moblie-title{
  text-align center
  font-weight: bold;
font-size: 21px;
color: #1B2532;
padding-top 21px
}

.contact-us-moblie-desc{
  margin-top 7px
  text-align center
  font-weight: 400;
font-size: 12px;
color: #000000;
}
.moblie-form{
  padding 0 14px
  margin-top 21px
  padding-bottom 30px
}
>>>.el-select__tags{
  height 50px !important
  overflow auto
}



@media screen and (min-width: 1024px) {
  .contact-us-moblie{
 display: none;
}

}


</style>
