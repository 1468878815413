<template>
  <div class="page-footer">
    <!-- 二维码 -->
    <div class="conact-wx">
      <div class="wx-warp">
        <div class="wx-left">
          <div class="logo"><img :src="require('@/assets/logo @2x.png')" alt="" /></div>
          <div class="compnay-title">
            <div class="title-text">北京一标数字科技有限公司</div>
            <div class="title-text">合作联系，账号开通，获取OneESG更多信息</div>
            <div class="us-mailbox">contact@oneesg.cn</div>
          </div>
        </div>
        <div class="wx-right">
          <div class="qr-code">
            <img :src="require('@/assets/qrcode.png')" alt="" />
          </div>
          <div class="qr-desc">微信公众号</div>
        </div>
      </div>
    </div>
    <div class="page-home__record">
      © 2022 oneesg.cn 版权所有 备案号：京ICP备2022018000号-2·京公网安备 11010802039640
    </div>
    <div class="conact-moblie-box">
      <div class="concat-left-icon"><img :src="require('@/assets/logo @2x.png')" alt="" /></div>
      <div class="concat-right-box">
        <div class="concat-right-box-name">北京一标数字科技有限公司</div>
        <div class="concat-right-box-name">合作联系，账号开通，获取OneESG更多信息</div>
        <div class="concat-right-box-emeail">contact@oneesg.cn</div>
        <div class="concat-right-img">
          <img :src="require('@/assets/qrcode.png')" alt="" />
        </div>
        <div class="concat-right-desc">
          微信公众号
        </div>
      </div>
    </div>
    <div class="page-moblie">
      <div class="liness"></div>
      <div class="moblie-box">
        © 2022 oneesg.cn 版权所有 备案号：京ICP备2022018000号-2·京公网安备 11010802039640
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  components: {},

  data() {
    return {};
  },

  async mounted() { },
};
</script>

<style scoped lang="stylus">
.conact-wx{
  width: 100%;
  height: 239px;
  background: #fff;
}
.page-moblie{
  display none
}

.logo{
  width: 120px;
  height: 41px;
  img{
    width: 100%;
  }
}
.wx-left{
  display: flex;
  align-items: center;
}
.wx-warp{
  width: 1200px;
  height: 100%;
  margin: 0 auto;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.compnay-title{
  margin-left: 50px;
}

.title-text{
  font-weight: 400;
  font-size: 16px;
  color: #738093;
  line-height: 30px;
  text-align: left;
  font-style: normal;
}
.us-mailbox{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  color: #0ECB92;
  line-height: 24px;
  text-align: left;
  font-style: normal;
}

.qr-code{
  width: 120px;
  height: 120px;
  img{
    width: 100%;
  }
}

.qr-desc{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 16px;
  color: #738093;
  line-height: 16px;
  text-align: center;
  font-style: normal;
}

.conact-moblie-box{
  display none
}
.page-home__record {
    width: 1200px;
    height: 51px;
    font-size: 16px;
    border-top: 1px solid #eee;
    font-weight: 400;
    color: #666666;
    line-height: 51px;
    text-align: center;
    margin 0 auto
  }
  @media only screen and (max-width: 768px) {


  .conact-wx{
    display none
  }

 .page-home__record{
  display none
 }
 .page-moblie{
  display block
  box-sizing border-box
  padding 0 14px
  background: #F4F7FC;
}
.moblie-box{
  height 46px
  font-size 12px
  color: #738093;
  text-align center
  display flex
  box-sizing border-box
  align-items center

}

.liness{
  width 100%
  height: 1px;
background: #DEE2E6;
}

.conact-moblie-box{
  box-sizing border-box
  display flex
  height 330px
  background: #F4F7FC;
  padding 0 14px
  padding-top 20px
}

.concat-left-icon{
  padding-top 20px
  img{
    width: 84px;
height: 28px;
  }
}

.concat-right-box-name{
  font-size 12px
  color: #738093;
  text-align: left;
  margin-top 10px
}

.concat-right-box{
  margin-left 10px
}

.concat-right-box-emeail{
  font-size 12px
  color: #0ECB92;
  margin-top 10px
}

.concat-right-desc{
  color: #738093;
  padding-left 10px
}

.concat-right-img{
  margin-top 10px
}
  }
</style>
