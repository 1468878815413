<template>
  <div class="page-esg" v-loading="loading">
    <div class="page-tab-list">
      <div class="tab-main-fff">
        <div class="tab-text-aaa" v-for="item in tabList" :key="item.code" @click="swtichTab(item.code)"
          :class="[currTab == item.code ? 'active' : '']">

          <img :src="require(`@/assets/${currTab == item.code ? item.act : item.def}`)" alt=""> <span>{{ item.name
            }}</span>
        </div>
      </div>
    </div>
    <div class="page-esg__content">
      <div class="page-esg__content_item" v-for="(s, i) in dataList" :key="i" @click="handleToOrigin(s.linkUrl)">
        <div class="page-esg__content_item_main">
          <div class="page-esg__content_item_main_img"><img @load="handleImgLoad(i)" :id="'img' + i"
              :src="s.coverImgUrl" alt=""></div>
          <div class="page-esg__content_item_main_nolimg" :id="'nolimg' + i"></div>
          <div class="page-esg__content_item_main_content">
            <div class="page-esg__content_item_main_content_title">{{ s.title }}</div>
            <div class="page-esg__content_item_main_content_desg">{{ s.summar }}</div>
            <div class="page-esg__content_item_main_content_date">
              <span v-if="currTab !== 'esg_books'">{{ s.originDesc }}</span>
              <span v-if="currTab !== 'esg_books'">{{ s.updateTime && s.updateTime.slice(0, s.updateTime.length -
    3) }}</span>
              <span v-if="currTab === 'esg_books'">{{ s.auther + ' 著' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-esg-moblie">
      <div class="esg-moblie-item" v-for="item in dataList" :key="item.id" @click="handleToOrigin(item.linkUrl)">
        <div class="item-left">
          <img :src="item.coverImgUrl" alt="">
        </div>
        <div class="item-right">
          <div class="item-name">{{ item.title }}</div>
          <div class="item-date"> <span v-if="currTab !== 'esg_books'">{{ item.originDesc }}</span>
            <span v-if="currTab !== 'esg_books'">{{ item.updateTime && item.updateTime.slice(0, item.updateTime.length -
    3) }}</span>
            <span v-if="currTab === 'esg_books'">{{ item.auther + ' 著' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="page-esg__pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" background layout="prev, pager, next, sizes, total"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNewsTab, getNewsList } from '@/request/fetch.js'

export default {
  name: 'HeaderEsg',

  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      tabList: [
        { label: 'ESG研究', value: 1 },
        { label: 'ESG资讯', value: 2 },
        { label: '出版物', value: 3 },
      ],
      tabMap: {
        'esg_research': {
          act: 'ct1.png',
          def: 'ct11.png'
        },
        'esg_news': {
          act: 'ct2.png',
          def: 'ct22.png'
        },
        'esg_books': {
          act: 'ct3.png',
          def: 'ct33.png'
        },
      },
      total: 100,
      pageSize: 10,
      currentPage: 1,
      activeName: 'second',
      currTab: 'esg_research',
      dataList: [],
      imgList: []
    }
  },

  async mounted() {
    // this.loading = true
    const tabRes = await getNewsTab()
    if (tabRes) {
      this.tabList = [...tabRes.data]
      this.tabList.forEach(item => {
        item.act = this.tabMap[item.code].act
        item.def = this.tabMap[item.code].def
      })
      console.log(this.tabList)
      this.currTab = tabRes.data && tabRes.data[0] && tabRes.data[0].code
    }
    const pam = {
      size: this.pageSize,
      page: this.currentPage,
      categoryCode: this.currTab
    }
    const dataList = await getNewsList(pam)
    if (dataList) {
      console.log(dataList)
      this.dataList = [...(dataList?.data?.itemList || [])]
      this.total = dataList?.data?.total || 0
    }
  },

  methods: {
    handleToOrigin(url) {
      window.open(url)
    },

    handleImgLoad(i) {
      document.getElementById('nolimg' + i).style.opacity = 0
    },

    handleImageLoad() {
      this.dataList.map((s, i) => {

        const img = new Image()
        img.src = s.coverImgUrl
        img.onload = function () {
          document.getElementById('img' + i).style.width = `384px`
          document.getElementById('img' + i).style.height = `${384 * (img.height / img.width)}px`
          document.getElementById('nolimg' + i).style.opacity = 0
        }
      })
    },

    swtichTab(code) {
      this.handleTabChange(code)
    },
    async handleTabChange(code) {
      this.currTab = code
      this.currentPage = 1
      this.dataList = []
      await this.handleFetchList()
    },

    async handleFetchList() {
      // this.loading = true
      const res = await getNewsList({
        size: this.pageSize,
        page: this.currentPage,
        categoryCode: this.currTab
      })
      if (res) {
        this.dataList = [...(res?.data?.itemList || [])]
        this.total = res?.data?.total || 0
      }
    },

    async handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      // await this.handleUpdateTableColumn()
      // await this.handleUpdateTableData()
      await this.handleFetchList()
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      // await this.handleUpdateTableColumn()
      // await this.handleUpdateTableData()
      await this.handleFetchList()
    },
  }

  // watch: {
  //   imgList
  // }
}
</script>

<style scoped lang="stylus">
>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color:  #0ECB92;
  color: #FFF;
}
>>>.el-pager{
  .btn-next{
    color: #0ECB92  !important;
  }
}
.page-esg {
  width: 100%;
  background: #F4F5F7;
  padding-top 20px
  &__pagination {
    margin-top 20px
    padding-bottom 30px
    display: flex;
    justify-content: center;
  }

  &__content {
    width: 1200px;
    margin: 0 auto;
  

    &_item {
      margin-top 20px;
      height: 216px;
      padding: 20px;
    background:#FFF;
      cursor: pointer;

      &_main {
        display: flex;
        position relative
        &_img {
          width: 384px;
          height: 216px;
          overflow: hidden;
          margin-left: 10px;
          border-radius: 4px;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            // border-radius: 4px;
            object-fit: cover;
          }
        }

        &_nolimg {
          width: 384px;
          height: 216px;
          background: #ebeef5;
          margin-left: 10px;
          position: absolute;
          border-radius: 4px;
        }

        &_content {
          width: 726px;
          padding-right: 10px;
          text-align: left;
          
          margin-left 30px
          &_title {
            width: 726px;
            // height: 80px;
            font-size: 26px;
            font-weight: 500;
            color: #333333;
            line-height: 37px;
            margin-bottom: 20px;
          }

          &_desg {
            width: 726px;
            height:100px;
            
            font-weight: 400;
            font-size: 16px;
            color: #738093;
            line-height: 26px;
            text-align: justify;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp:3;
          }

          &_date {
            font-size: 12px;
            font-weight: 400;
            color: #738093 ;
            position absolute
            bottom 0
            :nth-child(1) {
              margin-right: 20px;
            }
          }
        }
      }
    }

    &_item:hover {
      z-index: 122;
    }
  }

  &__tab {
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    background: #FFFFFF;
    margin-bottom 20px
    &_main {
      width: 1200px;
      margin: 0 auto;
      padding-top 10px
      span {
        display: inline-block;
        margin-right: 100px;
        font-weight: 500;
        font-size: 14px;
         color: #738093;
        line-height: 36px;
      }
    }

    img {
      width: 100%;
    }
  }
}


>>>.tabs__item{
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 500;
font-size: 14px;
color: #738093;
}

>>>.tabs__active{
  display none
}

>>>.tabs{
  z-index 1
}

.page-tab-list{
  width 100%
}

.tab-main-fff{
  width 1200px
  margin  0 auto
  height: 60px;
background: #FFFFFF;
display flex
align-items center
position relative
z-index 2
}

.tab-text-aaa{
  width 108px
  font-weight: 500;
  font-size: 14px;
  color: #738093;
  margin-left 10px
  height 34px
  line-height 34px
  text-align center
  cursor pointer
  display flex
  align-items center
  justify-content center
  img{
    width 16px
    margin-right 10px
  }
}

.active{
  background: #0ECB92;
border-radius: 4px;
color #fff
}
@media only screen and (max-width: 768px) {
  .page-esg__content{
    display: none;
  }
  .tab-main-fff{
    width 100%
  }
}

.page-esg-moblie{
  width 100%; 
  box-sizing border-box
  padding 0 14px
}

.esg-moblie-item{
  height: 90px;
  background: #FFFFFF;
  margin-top 14px
  padding 14px
  display flex
  align-items center
}

.item-left{
  width: 90px;
height: 63px;
img{
  width 100%;
  height  100%;
  object-fit: cover;
}
}

.item-right{
  width 219px
  margin-left 14px
}

.item-name{
  font-weight: bold;
  font-size: 14px;
  color: #2B3440;
  white-space: nowrap; /* 禁止折行 */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.item-date{
  font-size 12px
  color: #738093;
  margin-top 10px
}

@media screen and (min-width: 1024px) {
  .page-esg-moblie{
 display: none;
}
.page-esg__pagination{
  margin 0 auto
  margin-top 20px
  width 1200px !important
  justify-content: end;
}
}
</style>
