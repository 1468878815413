var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-data"},[_c('div',{staticClass:"page-banner"},[_c('div',{staticClass:"banner-mask"},[_c('div',{staticClass:"banner-title"},[_vm._v("OneESG数据产品")]),_c('div',{staticClass:"banner-subtitle",staticStyle:{"margin-top":"31px"}},[_vm._v("数据推动可持续发展未来")]),_c('div',{staticClass:"banner-desc"},[_vm._v("数据推动可持续发展未来")]),_c('div',{staticClass:"one-button",on:{"click":function($event){_vm.applyPageState = true}}},[_vm._v("申请试用")])])]),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"page-moblie"},[_c('div',{staticClass:"page-m-title"},[_vm._v("OneESG数据产品")]),_c('div',{staticClass:"page-m-desc"},[_vm._v("数据推动可持续发展未来")]),_c('div',{staticClass:"one-button",on:{"click":function($event){_vm.applyPageState = true}}},[_vm._v("申请试用")])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('ContactUS'),(_vm.applyPageState)?_c('ApplyPage'):_vm._e(),_c('PageFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-product",attrs:{"id":"sf1"}},[_c('div',{staticClass:"product-container"},[_c('div',{staticClass:"product-title"},[_vm._v("OneESG价值核算数据")]),_c('div',{staticClass:"product-desc"},[_vm._v(" OneESG价值核算数据库提供独家ESG价值核算数据，量化反映会员企业对社会可持续性发展的贡献，识别可持续发展风险及机遇。提供企业ESG净值、ESG风险机遇值、ESG市盈率等数据。 ")]),_c('div',{staticClass:"product-tips"},[_c('div',{staticClass:"tips-left"},[_c('div',{staticClass:"tips-icon"},[_c('img',{attrs:{"src":require("../assets/t1212.png"),"alt":""}})]),_c('div',{staticClass:"tips-box"},[_c('div',{staticClass:"tip-tex"},[_vm._v("核算范围")]),_c('div',{staticClass:"tip-desc"},[_vm._v("全部 A 股上市公司和香港上市内地公司")])])]),_c('div',{staticClass:"tips-right"},[_c('div',{staticClass:"tips-icon"},[_c('img',{attrs:{"src":require("../assets/t123.png"),"alt":""}})]),_c('div',{staticClass:"tips-box"},[_c('div',{staticClass:"tip-tex"},[_vm._v("历史长度")]),_c('div',{staticClass:"tip-desc"},[_vm._v("2017年起")])])])]),_c('div',{staticClass:"product-im"},[_c('img',{attrs:{"src":require("../assets/adf.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-product",staticStyle:{"background":"#f5f6f9"},attrs:{"id":"sf2"}},[_c('div',{staticClass:"product-container"},[_c('div',{staticClass:"product-title"},[_vm._v("OneESG底层数据")]),_c('div',{staticClass:"product-desc"},[_vm._v(" 提供多年海量结构化企业披露ESG数据及独家ESG数据缺省补全数据，涵盖环境管理、降污减排、气候变化、资源使用、生物多样性、ESG治理、公司治理等各个层面。 ")]),_c('div',{staticClass:"product-tips"},[_c('div',{staticClass:"tips-left"},[_c('div',{staticClass:"tips-icon"},[_c('img',{attrs:{"src":require("../assets/t1212.png"),"alt":""}})]),_c('div',{staticClass:"tips-box"},[_c('div',{staticClass:"tip-tex"},[_vm._v("核算范围")]),_c('div',{staticClass:"tip-desc"},[_vm._v("全部 A 股上市公司和香港上市内地公司")])])]),_c('div',{staticClass:"tips-right"},[_c('div',{staticClass:"tips-icon"},[_c('img',{attrs:{"src":require("../assets/t123.png"),"alt":""}})]),_c('div',{staticClass:"tips-box"},[_c('div',{staticClass:"tip-tex"},[_vm._v("历史长度")]),_c('div',{staticClass:"tip-desc"},[_vm._v("2017年起")])])])]),_c('div',{staticClass:"product-im"},[_c('img',{attrs:{"src":require("../assets/adddv.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-product"},[_c('div',{staticClass:"product-container"},[_c('div',{staticClass:"product-title"},[_vm._v("应用场景")]),_c('div',{staticClass:"scene"},[_c('div',{staticClass:"scene-item"},[_c('div',{staticClass:"item-top"},[_c('img',{attrs:{"src":require("../assets/a186.png"),"alt":""}})]),_c('div',{staticClass:"item-title"},[_vm._v("ESG金融研究")]),_c('div',{staticClass:"item-desc"},[_vm._v(" 帮助金融分析师和高校老师研究以环境、社会和治理因素为核心的金融理论和实践，探索ESG因素与企业财务表现相关性，将ESG因素纳入投资决策 ")])]),_c('div',{staticClass:"scene-item"},[_c('div',{staticClass:"item-top"},[_c('img',{attrs:{"src":require("../assets/a187.png"),"alt":""}})]),_c('div',{staticClass:"item-title"},[_vm._v("投资组合构建")]),_c('div',{staticClass:"item-desc"},[_vm._v("ESG学术研究课题价值攀升，ESG跨学科研究领域广，为研究者提供高质量ESG数据库。")])]),_c('div',{staticClass:"scene-item"},[_c('div',{staticClass:"item-top"},[_c('img',{attrs:{"src":require("../assets/a188.png"),"alt":""}})]),_c('div',{staticClass:"item-title"},[_vm._v("ESG模型和评级")]),_c('div',{staticClass:"item-desc"},[_vm._v(" 协助金融机构和大型企业集团搭建ESG数据模型和评级体系，识别客户和供应商ESG风险，提升ESG竞争力 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-moblie-data"},[_c('div',{staticClass:"moblie-data-title"},[_vm._v("OneESG价值核算数据")]),_c('div',{staticClass:"moblie-data-desc"},[_vm._v(" OneESG价值核算数据库提供独家ESG价值核算数据，量化反映会员企业对社会可持续性发展的贡献，识别可持续发展风险及机遇。提供企业ESG净值、ESG风险机遇值、ESG市盈率等数据。 ")]),_c('div',{staticClass:"moblie-box-icon"},[_c('div',{staticClass:"box-icon-left"},[_c('img',{attrs:{"src":require("../assets/t1212.png"),"alt":""}})]),_c('div',{staticClass:"box-icon-right"},[_c('div',{staticClass:"box-tip-top"},[_vm._v("核算范围")]),_c('div',{staticClass:"box-tip-top-desc"},[_vm._v("全部A股上市公司和香港上市内地公司")])])]),_c('div',{staticClass:"moblie-box-icon"},[_c('div',{staticClass:"box-icon-left"},[_c('img',{attrs:{"src":require("../assets/t123.png"),"alt":""}})]),_c('div',{staticClass:"box-icon-right"},[_c('div',{staticClass:"box-tip-top"},[_vm._v("历史长度")]),_c('div',{staticClass:"box-tip-top-desc"},[_vm._v("2017年起")])])]),_c('div',{staticClass:"mobolie-img"},[_c('img',{attrs:{"src":require("../assets/mn1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-moblie-data"},[_c('div',{staticClass:"moblie-data-title"},[_vm._v("OneESG底层数据")]),_c('div',{staticClass:"moblie-data-desc"},[_vm._v(" 提供多年海量结构化企业披露ESG数据及独家ESG数据缺省补全数据，涵盖环境管理、降污减排、气候变化、资源使用、生物多样性、ESG治理、公司治理等各个层面。 ")]),_c('div',{staticClass:"moblie-box-icon"},[_c('div',{staticClass:"box-icon-left"},[_c('img',{attrs:{"src":require("../assets/t1212.png"),"alt":""}})]),_c('div',{staticClass:"box-icon-right"},[_c('div',{staticClass:"box-tip-top"},[_vm._v("核算范围")]),_c('div',{staticClass:"box-tip-top-desc"},[_vm._v("全部A股上市公司和香港上市内地公司")])])]),_c('div',{staticClass:"moblie-box-icon"},[_c('div',{staticClass:"box-icon-left"},[_c('img',{attrs:{"src":require("../assets/t123.png"),"alt":""}})]),_c('div',{staticClass:"box-icon-right"},[_c('div',{staticClass:"box-tip-top"},[_vm._v("历史长度")]),_c('div',{staticClass:"box-tip-top-desc"},[_vm._v("2017年起")])])]),_c('div',{staticClass:"mobolie-img"},[_c('img',{attrs:{"src":require("../assets/mn3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"application-box-aa"},[_c('div',{staticClass:"application-moblie-title"},[_vm._v("应用场景")]),_c('div',{staticClass:"application-moblie-item"},[_c('div',{staticClass:"application-moblie-left"},[_c('img',{attrs:{"src":require("../assets/a186.png"),"alt":""}})]),_c('div',{staticClass:"application-moblie-right"},[_c('div',{staticClass:"application-moblie-top"},[_vm._v("ESG金融研究")]),_c('div',{staticClass:"application-moblie-desc"},[_vm._v(" 帮助金融分析师和高校老师研究以环境、社会和治理因素为核心的金融理论和实践，探索ESG因素与企业财务表现相关性，将ESG因素纳入投资决策 ")])])]),_c('div',{staticClass:"application-moblie-item"},[_c('div',{staticClass:"application-moblie-left"},[_c('img',{attrs:{"src":require("../assets/a187.png"),"alt":""}})]),_c('div',{staticClass:"application-moblie-right"},[_c('div',{staticClass:"application-moblie-top"},[_vm._v("投资组合构建")]),_c('div',{staticClass:"application-moblie-desc"},[_vm._v(" ESG学术研究课题价值攀升，ESG跨学科研究领域广，为研究者提供高质量ESG数据库 ")])])]),_c('div',{staticClass:"application-moblie-item"},[_c('div',{staticClass:"application-moblie-left"},[_c('img',{attrs:{"src":require("../assets/a188.png"),"alt":""}})]),_c('div',{staticClass:"application-moblie-right"},[_c('div',{staticClass:"application-moblie-top"},[_vm._v("ESG模型和评级")]),_c('div',{staticClass:"application-moblie-desc"},[_vm._v(" 协助金融机构和大型企业集团搭建ESG数据模型和评级体系，识别客户和供应商ESG风险，提升ESG竞争力 ")])])])])
}]

export { render, staticRenderFns }