<template>
  <div class="page-about">
    <div class="about-us">
      <div class="about-container">
        <div class="about-content">
          <div class="about-title">关于OneESG</div>
          <div class="about-desc">
            OneESG平台是为投资提供量化ESG数据服务的平台，核心目标是用一个财务化指标反映公司的ESG价值。平台由北京一标数字科技有限公司推出，公司创始人拥有二十年CSR／ESG研究经验，多年CSR／ESG价值量化研究和服务经验。
          </div>
        </div>
      </div>
    </div>
    <div class="core-team">
      <div class="team-title">核心团队</div>
      <div class="core-cotainer">
        <div class="core-item" v-for="(item, index) in teamList" :key="index">
          <div class="top-img">
            <img :src="require(`@/assets/${item.img}`)" alt="" />
          </div>
          <div class="top-name">{{ item.name }}</div>
          <div class="top-desc">{{ item.desc }}</div>
          <div class="top-val">{{ item.val }}</div>
        </div>
      </div>
      <div class="about-container-moblie">
        <div class="about-item" v-for="item in teamList" :key="item.img">
          <div class="about-item-left">
            <div class="left-top"><img :src="require(`@/assets/${item.img}`)" alt="" /></div>
            <div class="left-name">{{ item.name }}</div>
            <div class="left-desc">{{ item.desc }}</div>
          </div>
          <div class="about-item-right">{{ item.val }}</div>
        </div>
      </div>
    </div>
    <ContactUS></ContactUS>
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import PageFooter from './footer';
import ContactUS from './contactUs.vue';
export default {
  name: 'AboutPage',
  components: { PageFooter, ContactUS },

  data() {
    return {
      teamList: [
        {
          name: '殷格非',
          desc: '创始人兼CEO',
          img: 'one.png',
          val: '责扬天下管理顾问创始人，20余年从业经历：金蜜蜂智库创始人、责扬天下首席专家、ISO TC322可持续金融国际标准对口工作组专家。中国CSR/ESG量化技术研究领先者：2017年研发推出责任竞争力量化技术，2018年首创企业单位净资产综合价值计算技术，研发ESG价值计算技术。',
        },
        {
          name: '吴福顺',
          desc: '联合创始人兼COO',
          img: 'two.png',
          val: '2003年参与金蜜蜂智库创建，从业经验跨ESG咨询、管理、技术及投资。2003-2011从事CSR/ESG咨询；2012-2018在蒙牛、美团负责CSR管理；2009年起，从事ESG技术及ESG投资，代表性项目有生态环保消费帮扶电商平台、消费扶贫技术服务平台、数字乡村产业服务平台。',
        },
        {
          name: '代奕波 ',
          desc: '联合创始人兼产品经理',
          img: 'three.png',
          val: '超过15年CSR/ESG工作经验。企业ESG战略、管理及信息披露专家，客户涵盖电力、ICT、交通运输、金融、医药、采掘等行业。《ESG竞争力》《ESG管理与信息披露实务》主编。',
        },
      ],
    };
  },

  computed: {},

  mounted() { },

  methods: {},
};
</script>

<style scoped lang="stylus">
.about-us{
  width: 100%;
  height:300px;
  background: url('../assets/su37.png') center center no-repeat;
  background-size cover
}

.about-content{
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.about-container{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
}
.about-title{
  text-align: center;
  color: #fff;
  font-size: 40px;
  padding-top: 98px;
  font-weight: bold;
}
.about-desc{
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  text-align: center;
  padding-top: 17px;
}
.core-team{
  width: 100%;
  padding-bottom: 100px;
}
.core-cotainer{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 60px;
}
.team-title{
  text-align: center;
  font-size: 40px;
  color: #1B2532;
  font-weight: bold;
  padding-top: 60px;
}
.core-item{
  width: 380px;
  height: 480px;
  background: #F2F3F6;
}
.top-img{
  width: 120px;
  height: 120px;
  margin: 0 auto;
  padding-top 30px
  img{
    width: 100%;
  }
}
.top-name{

    text-align: center;
    height: 36px;
    font-size: 34px;
    font-weight: bold;
    color: #0ECB92;
}
.top-val{
    text-align: justify;
    margin: 0 auto;
    width: 300px;
    font-size: 14px;
    color: #738093;
    font-weight: 400;
    line-height: 28px;
}

.top-desc{
  margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
    background: #0ECB92;
    border-radius: 14px;
    height 28px
    width 160px
    color #FFF
    display flex
    align-items center
    justify-content: center;
    margin-top 20px
    margin-bottom 20px
 
}
@media only screen and (max-width: 768px) {
  .about-us{
    background: url('../assets/su37.png') center center / 750px 400px no-repeat;
  }
  .about-title{
    padding-top 40px
  }
  .about-content{
    width: 100%;
  }
  .core-cotainer{
    display none
  }
  .about-desc{
    font-size: 14px;
    padding: 0 37px;
    text-align justify
    margin-top 10px
  }
  .core-cotainer{
    width: 100%;
    padding: 0 33px;
    box-sizing: border-box;
  }
  .core-item{
    width: 100%;
    height:  auto;
    margin: 0 auto;
    padding-bottom: 20px;
    margin-top: 40px;
  }
}

.about-container-moblie{
  width 100%
  box-sizing border-box
  padding 0 14px
}

.about-item{
  width 100%;
  min-height 160px
  background: #F2F3F6;
  box-sizing border-box
  padding 10px
  display flex
  align-items center
  margin-top 20px
}

.about-item-right{
  font-size 12px
  color: #738093;
line-height: 17px;
text-align: justify;
margin-left 10px
}

.left-top{
  width 56px
  height  56px
  img{
    width 100%
  }
}
.about-item-left{
  width: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left-name{
  text-align center
  min-width 94px
  font-size: 18px;
color: #0ECB92;
margin-top 5px
}

.left-desc{
  margin-top 5px
  min-width 94px
  width: 134px;
height: 17px;
background: #0ECB92;
border-radius: 9px;
font-size:12px;
color: #FFFFFF;
text-align center
}

@media screen and (min-width: 1024px) {
  .about-container-moblie{
 display: none;
}
.sider{
  display: none !important
}
}
</style>
