<template>
  <div class="page-home" v-loading="loading" @click="tipsLogin = false">
    <div class="block">
      <div class="mask-dov">
        <div class="mask-tip">
          <div class="mask-item">
            <div class="mask-left-img"><img src="../assets/m1.png" alt=""></div>
            <div class="mask-right-desc">
              <div class="right-top-a">6000+</div>
              <div class="right-top-desc">全部A股上市公司和香港上市内地公司</div>
            </div>
          </div>
          <div class="mask-item">
            <div class="mask-left-img"><img src="../assets/m2.png" alt=""></div>
            <div class="mask-right-desc">
              <div class="right-top-a">30+</div>
              <div class="right-top-desc">ESG价值核算模型</div>
            </div>
          </div>
          <div class="mask-item">
            <div class="mask-left-img"><img src="../assets/m3.png" alt=""></div>
            <div class="mask-right-desc">
              <div class="right-top-a">4000+</div>
              <div class="right-top-desc">企业社会责任评价</div>
            </div>
          </div>
          <div class="mask-item">
            <div class="mask-left-img"><img src="../assets/m4.png" alt=""></div>
            <div class="mask-right-desc">
              <div class="right-top-a">200+</div>
              <div class="right-top-desc">ESG领域及其他技术领域专家</div>
            </div>
          </div>
        </div>
      </div>
      <el-carousel ref="bo" trigger="click" height="436px" arrow="never" :interval="5000">
        <el-carousel-item class="carousel-item">
          <div class="car-contaienr">
            <div class="car-top">
              中国上市公司ESG价值核算报告（2023）
            </div>
            <div class="car-top-desc">
              建立中国可持续金融创新发展的标准基础，推动全球可持续金融发展
            </div>
            <div class="one-button" @click="downPdf">立即下载</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carousel-itemss">
          <div class="car-contaienr">
            <div class="car-top">
              OneESG价值核算数据
            </div>
            <div class="car-top-desc">
              OneESG致力于提供卓越的可持续发展数字化平台。依托云计算、大数据和AI技术，结合资深ESG领域专家，为客户提供卓越数字化平台工具
            </div>
            <div class="one-button" @click.stop="handleClickSubmits">申请试用</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carousel-items">
          <div class="car-contaienr">
            <div class="car-top">
              双碳目标一站式数字智能平台
            </div>
            <div class="car-top-desc">
              基于碳领域多年咨询经验与数字化技术研发，为企业/园区/政府提供数字化、智能化气候风险、碳中和解决方案
            </div>
            <div class="one-button" @click.stop="handleClickSubmits">申请试用</div>
          </div>
        </el-carousel-item>

      </el-carousel>
    </div>
    <div class="moblie-block">
      <div class="moblie-mask-div">
        <div class="moblie-mask-item">
          <div class="mask-left-img-moblie"><img src="../assets/m1.png" alt=""></div>
          <div class="mask-right-desc">
            <div class="right-top-a-moblie">6000+</div>
            <div class="right-top-desc-moblie">全部 A 股上市公司和香港上市内地公司</div>
          </div>
        </div>
        <div class="moblie-mask-item">
          <div class="mask-left-img-moblie"><img src="../assets/m2.png" alt=""></div>
          <div class="mask-right-desc" style="padding-top: 7px;">
            <div class="right-top-a-moblie">30+</div>
            <div class="right-top-desc-moblie">ESG价值核算模型</div>
          </div>
        </div>
        <div class="moblie-mask-item">
          <div class="mask-left-img-moblie"><img src="../assets/m3.png" alt=""></div>
          <div class="mask-right-desc" style="padding-top:7px;">
            <div class="right-top-a-moblie">4000+</div>
            <div class="right-top-desc-moblie">企业社会责任评价</div>
          </div>
        </div>
        <div class="moblie-mask-item">
          <div class="mask-left-img-moblie"><img src="../assets/m4.png" alt=""></div>
          <div class="mask-right-desc">
            <div class="right-top-a-moblie">200+</div>
            <div class="right-top-desc-moblie">ESG领域及其他技术领域专家</div>
          </div>
        </div>
      </div>
      <el-carousel ref="bo" trigger="click" height="428px" arrow="never" :interval="5000">
        <el-carousel-item class="page-moblie-one">
          <div class="car-contaienr-moblie">
            <div class="car-top-moblie">
              中国上市公司ESG价值核算报告（2023）
            </div>
            <div class="car-top-desc-moblie">
              建立中国可持续金融创新发展的标准基础，推动全球可持续金融发展
            </div>
            <div class="one-button-moblie" @click="downPdf">立即下载</div>
          </div>
        </el-carousel-item>

        <el-carousel-item class="page-moblie-three">
          <div class="car-contaienr-moblie">
            <div class="car-top-moblie">
              OneESG价值核算数据
            </div>
            <div class="car-top-desc-moblie">
              OneESG致力于提供卓越的可持续发展数字化平台。依托云计算、大数据和AI技术，结合资深ESG领域专家，为客户提供卓越数字化平台工具
            </div>
            <div class="one-button-moblie" @click.stop="handleClickSubmits">申请试用</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="page-moblie-two">
          <div class="car-contaienr-moblie">
            <div class="car-top-moblie">
              双碳目标一站式数字智能平台
            </div>
            <div class="car-top-desc-moblie">
              基于碳领域多年咨询经验与数字化技术研发，为企业/园区/政府提供数字化、智能化气候风险、碳中和解决方案
            </div>
            <div class="one-button-moblie" @click.stop="handleClickSubmits">申请试用</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 服务 -->
    <div class="page-home-desc">
      <div class="page-title">领先的ESG价值核算数据服务</div>
      <div class="page-subtitle">
        首创将 ESG 因素进行价值核算的方法，用 20 年社会责任 /ESG
        专业经验赋能数据质量管理，开创性地研发出ESG价值核算数据。
      </div>
      <div class="page-subdesc">
        基于ESG价值核算方法，为高校、金融机构、企业、政府机关提供ESG数据产品、数字化工具及ESG应用服务。
      </div>
      <div class="page-moblie-desc">
        首创将 ESG 因素进行价值核算的方法，用 20 年社会责任 /ESG
        专业经验赋能数据质量管理，开创性地研发出ESG价值核算数据。基于ESG价值核算方法，为高校、金融机构、企业、政府机关提供ESG数据产品、数字化工具及ESG应用服务。
      </div>
      <div class="page-bg-home">
        <img style="width:685px;height:253px" src="../assets/homeBG.png" alt="">
      </div>
      <div class="page-bg-home-moble">
        <img src="../assets/bgwww.png" alt="" class="AAS-I">
      </div>

    </div>
    <!-- 优势 -->
    <div class="page-advantage">
      <div class="advantage-title">我们的优势</div>
      <div class="advantage-desc-text"> 依托20年CSR/ESG咨询经验，核算企业ESG外部化的成本和价值</div>
      <div class="advantage-warp">
        <div class="advantage-item">
          <div class="advantage-top"><img src="../assets/home-three2.png" alt="" /></div>
          <div class="advantage-sj">
            <div class="advantage-text">全球唯一</div>
            <div class="advantage-desc">
              全球首家将非财务因素（ESG）进行货币化核算，符合全球可持续相关财务信息披露前沿研究趋势
            </div>
          </div>
        </div>
        <div class="advantage-item">
          <div class="advantage-top"><img src="../assets/home-three4.png" alt="" /></div>
          <div class="advantage-sj">
            <div class="advantage-text">质量保障</div>
            <div class="advantage-desc">
              基于AI智能识别技术建立结构化数据库，100+咨询师进行数据复审，20年ESG专业经验赋能数据质量管理
            </div>
          </div>
        </div>
        <div class="advantage-item">
          <div class="advantage-top"><img src="../assets/home-three1.png" alt="" /></div>

          <div class="advantage-sj">
            <div class="advantage-text">专业团队</div>
            <div class="advantage-desc">
              6年超过4000家企业进行社会责任评价经验，拥有一支200多人的具有多元复合背景的ESG及行业专家和技术专家团队
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品 -->
    <div class="page-product">
      <div class="product-title">OneESG数据产品</div>
      <div class="product-warp">
        <div class="product-item">
          <div class="product-top"><img src="../assets/jiazhi.png" alt="" /></div>
          <div class="product-subtitlte">OneESG价值核算数据</div>
          <div class="product-desc">
            独家ESG价值核算数据，量化反映会员企业对社会可持续性发展的贡献，识别可持续发展风险及机遇。提供核算 ESG
            净值、ESG 风险机遇值、ESG市盈率等数据。
          </div>
        </div>
        <div class="product-item">
          <div class="product-top"><img src="../assets/diceng.png" alt="" /></div>
          <div class="product-subtitlte">OneESG底层数据</div>
          <div class="product-desc">
            提供多年海量结构化企业披露ESG数据及独家ESG数据缺省补全数据，涵盖环境管理、降污减排、气候变化、资源使用、生物多样性、ESG治理、公司治理等各个层面。
          </div>
        </div>
      </div>
    </div>
    <div class="page-product-moblie">
      <div class="product-title-moblie">OneESG数据产品</div>
      <div class="product-warp-moblie">

        <div class="product-item-moblie">
          <div class="product-top-moblie"><img src="../assets/nv1.png" alt="" /></div>
          <div class="product-moblie-lfet">
            <div class="product-subtitlte-moblie">OneESG价值核算数据</div>
            <div class="product-desc-moblie">
              独家ESG价值核算数据，量化反映会员企业对社会可持续性发展的贡献，识别可持续发展风险及机遇。提供核算 ESG
              净值、ESG 风险机遇值、ESG市盈率等数据。
            </div>
          </div>

        </div>
        <div class="product-item-moblie">
          <div class="product-top-moblie"><img src="../assets/nv2.png" alt="" /></div>
          <div class="product-moblie-lfet">
            <div class="product-subtitlte-moblie">OneESG底层数据</div>
            <div class="product-desc-moblie">
              提供多年海量结构化企业披露ESG数据及独家ESG数据缺省补全数据，涵盖环境管理、降污减排、气候变化、资源使用、生物多样性、ESG治理、公司治理等各个层面。
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 平台 -->
    <div class="page-platform">
      <div class="platform-title">OneESG数字化平台</div>
      <div class="platform-warp">
        <div class="platform-list">
          <div class="list-item" @click="handleClick(i)" v-for="(s, i) in platformList" :key="i"
            :class="[i == activeIndex ? 'active' : '']">
            <img v-if="i != activeIndex" class="icnon-img" :src="require(`@/assets/${platformDataList[i].icon2}`)"
              alt="" />
            <img v-else class="icnon-img" :src="require(`@/assets/${platformDataList[i].icon}`)" alt="" />
            {{ s.label }}
          </div>
        </div>
        <div class="platform-center">
          <img class="platform-img" :src="require(`@/assets/${platformDataList[activeIndex].url}`)" alt="" />
          <div class="platform-desc">

            <div class="desc-text">{{ platformDataList[activeIndex].desc }}</div>
          </div>
        </div>
      </div>
      <div class="platform-tab-moblie-container">
        <div class="platform-tab-moblie">
          <div class="tab-items" @click="tabSwtich(i)" v-for="(s, i) in platformList" :key="i"
            :class="[moblieIndex == i ? 'active-box' : '']">{{ s.label }}</div>
        </div>
      </div>
      <div class="platform-warps-moblie">
        <div class="warps-moblie-ping"> <img :src="require(`@/assets/${platformDataList[moblieIndex].url}`)" alt=""
            class="img-mobile" />
          <div class="warps-moblie-desc">
            {{ platformDataList[moblieIndex].desc }}
          </div>
        </div>

      </div>
    </div>
    <!-- 应用服务 -->
    <div class="application-service">
      <div class="product-title" style="padding-top: 50px">OneESG应用服务</div>
      <div class="application-warp">
        <div class="application-item" v-for="(s, i) in serviceList" :key="i">
          <img :src="require(`@/assets/${s.url}`)" alt="" />
          <div class="application-right">
            <div class="application-subtitle">{{ s.label }}</div>
            <div class="application-desc">{{ s.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <ContactUS></ContactUS>
    <!-- 二维码 -->
    <PageFooter></PageFooter>
    <ApplyPage v-if="applyPageState"></ApplyPage>

  </div>
</template>

<script>
import { sendSms, commit } from '@/request/fetch';
import ContactUS from './contactUs.vue';
import PageFooter from './footer';
import ApplyPage from './apply.vue';
export default {
  name: 'HomePage',
  components: { ContactUS, PageFooter, ApplyPage },
  data() {
    return {
      moblieIndex: 0,
      applyPageState: false,
      activeIndex: 0,
      serviceList: [
        {
          label: '金融机构ESG风险管理',
          desc: '提供银行信贷客户ESG评级与风险管理，将ESG要素纳入到银行风控体系',
          url: 'c2.png',
        },
        {
          label: '企业供应链ESG合规管理',
          desc: '提供企业可持续供应链解决方案，搭建企业供应商ESG评价体系与绿色供应链平台',
          url: 'c4.png',
        },
        {
          label: '投资机构量化分析与指数研发',
          desc: '分析投资组合的ESG因素，ESG指数与咨询服务',
          url: 'c1.png',
        },
        {
          label: '碳盘查与碳管理',
          desc: '利用智能芯片技术，进行碳数据的实时收集、整理，为企业、政府、产业园区搭建碳智管理平台',
          url: 'c3.png',
        },
      ],

      platformDataList: [
        {
          label: 'OneESG价值核算数据平台',
          desc: '平台覆盖了对 2017 年以来的全部 A 股上市公司和香港上市内地公司的ESG价值核算数据，拥有企业多维度ESG价值分析功能，包含ESG价值核算报表、ESG价值核算报告、投资组合管理工具，助力高校、金融机构等客户将ESG因素纳入可持续投融资研究及决策。',
          url: 'a1b2.png',
          icon: 'icon11.png',
          icon2: "d1.png"
        },
        {
          label: 'OneESG碳智平台 ',
          desc: 'OneESG碳核算平台由资深碳咨询专家和技术数据团队精心打造，基于国际通用碳核算标准ISO 14064和GHG Protocol，结合人工智能技术，实施数据收集、整理、计算、分析、报告披露，三方认证，为您提供一站式碳排放量化和气候风险评估服务',
          url: '99.png',
          icon: 'icon12.png',
          icon2: "d2.png"
        },
        {
          label: 'OneESG智能数据披露平台',
          desc: '一站式ESG数据数据披露与智能分析产品。内置多种ESG标准指标体系，紧跟监管要求，实现标准化ESG指标管理。利用人工智能和大数据分析技术，对ESG数据进行深度挖掘和分析，为企业提供智能预警和决策支持。平台致力于为企业提供全面、准确、及时的ESG数据披露服务，帮助企业提升ESG表现，实现可持续发展目标。',
          url: '44.png',
          icon: 'icon13.png',
          icon2: "d3.png"
        },
      ],

      tipsLogin: false,
      loading: false,
      phone: '',
      code: '',
      msgVal: '',
      smsUuid: '',
      data: [],
      platformList: [
        { label: 'OneESG价值核算数据平台' },
        { label: 'OneESG碳智平台' },
        { label: 'OneESG智能数据披露平台' },
      ],
      threeList: [
        { label: 'A股和香港联交所中资股公司', value: '6000+' },
        { label: '高实质性的底层定量数据', value: '80+' },
        { label: 'ESG因素价值化算法模型', value: '20+' },
        { label: '资深ESG分析师', value: '100+' },
        { label: '中国企业（组织）CSR/ESG报告', value: '22600+' },
      ],
      threesList: [
        {
          label: '自动采集企业公开披露的定量ESG数据，资深分析师多维度标记数据质量',
          img: 'home-three1.png',
          title: '底层数据采集',
        },
        {
          label: '为每个底层数据指标建立适合的数据补全模型，对缺失数据进行赋值，并通过机器学习方法不断优化校准',
          img: 'home-three2.png',
          title: '缺失数据补全',
        },
        {
          label:
            '沉淀多年CSR／ESG咨询经验，建立ESG价值量化算法模型，将ESG因素进行定价，助力投资者将ESG表现融入估值体系',
          img: 'home-three3.png',
          title: '价值化算法',
        },
        {
          label:
            '制定全流程数据质量管理体系，数据产品均配有可溯质量评级标签，确保底层数据一致、准确、有效、可比，价值化算法权威、及时、充分反映外部性影响',
          img: 'home-three4.png',
          title: '数据质量保证',
        },
      ],
      fourList: [
        {
          title: 'ESG投资工作台',
          child: [
            '跟踪持有投资组合的ESG价值表现，根据需要调整持仓，确保ESG风险可控；',
            '根据ESG价值筛选条件，建立ESG价值优选组合，回测收益；',
            '专属ESG投资管理工作台，支持将各类主体（如潜在投资标的、信贷对象、分子公司、合作伙伴等）ESG数据导入OneESG平台，自动生成ESG价值结果，支持ESG决策与管理。',
          ],
        },
        {
          title: '行业ESG基准线',
          child: [
            '用一组财务数据直观展现公司ESG的基本面：ESG价值、ESG机遇显现值／风险敞口值、环境因素的机遇与风险、社会因素的机遇与风险、ESG市盈率。',
          ],
        },
        {
          title: '公司ESG基本面',
          child: [
            '根据企业ESG价值结果，核算各行业ESG价值基准水平，助力投资者跟踪各行业ESG价值创造的变化趋势，选出兼顾投资回报和ESG价值的“绿色”行业、规避可能存在ESG风险的“灰色”行业；选出行业内更具ESG价值创造能力的“好企业”。',
          ],
        },
      ],
      fiveList: [
        {
          label:
            'A股上市公司和H股中资上市公司的创造的ESG净价值核算结果数据，包括ESG净值、环境因素、社会因素的净值、每股ESG净值、单位营收ESG净值等。',
          img: 'five-one',
          title: 'ESG净值数据',
        },
        {
          label: '以行业ESG净值平均值为基准，用样本公司净值减去行业基准，得出正值为机遇显现值，负值为风险敞口值。',
          img: 'five-two',
          title: 'ESG机遇与风险数据',
        },
        {
          label: '以本数据库中的所有样本公司为基础，计算出各行业的ESG净值基准数据。',
          img: 'five-three',
          title: '行业ESG数据',
        },
        {
          label: 'A股上市公司和H股中资上市公司的ESG报告与财报中披露的定量ESG数据。',
          img: 'five-four',
          title: 'ESG底层数据',
        },
      ],
      curCombination: 0,
      smsNum: 60,
      smsBtnDisabled: false,
    };
  },

  watch: {
    smsCodeText: function () {
      return this.smsNum === 60 ? `获取验证码` : `${this.smsNum}s后重新发送`;
    },
    applyPageState(val) {
      if (val) {
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.getElementById('app').style.overflow = 'auto';
      }
    }
  },
  async mounted() {
    // this.loading = true;
    // const res = await getS();
    // if (res?.item?.length) {
    //   const arr = [
    //     'https://api.oneesg.cn/file/r/images/chance_top10_0619.jpg',
    //     'https://api.oneesg.cn/file/r/images/esg_top10_0619.jpg',
    //     'https://api.oneesg.cn/file/r/images/portfolio4.jpg',
    //     'https://api.oneesg.cn/file/r/images/portfolio3.jpg',
    //   ];
    //   this.data = [...res?.item]?.slice(0, 4);
    //   this.data?.map((s, i) => {
    //     s.coverUrl = arr[i];
    //   });
    // }
    // this.loading = false;
  },
  methods: {
    tabSwtich(i) {
      console.log(i, 'sdsd')
      this.moblieIndex = i
    },
    handleClickSubmits() {
      this.applyPageState = true
    },
    handleClick(i) {
      this.activeIndex = i;
    },
    downPdf() {
      window.open(
        'https://oneesg-public.oss-cn-beijing.aliyuncs.com/file/%E4%B8%AD%E5%9B%BD%E4%B8%8A%E5%B8%82%E5%85%AC%E5%8F%B8ESG%E4%BB%B7%E5%80%BC%E6%A0%B8%E7%AE%97%E6%8A%A5%E5%91%8A%282023%29.pdf'
      );
    },

    handleTipsLogin(id) {
      this.tipsLogin = true;
      this.curCombination = id;
    },
    handleTipsLoginColose() {
      this.tipsLogin = false;
      window.open('https://app.oneesg.cn?loginState=1');
    },

    handleGetSms: async function () {
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (ruleReg.test(this.phone) && !this.smsBtnDisabled) {
        this.smsBtnDisabled = true;
        let timer = setInterval(() => {
          --this.smsNum;
        }, 1000);
        setInterval(() => {
          clearInterval(timer);
          this.smsNum = 60;
          this.smsBtnDisabled = false;
        }, 60000);
      }
    },

    handleOpenAgreement() {
      let originUrl = window.origin;
      window.open(`${originUrl}/OneESG用户协议与隐私条款.pdf`);
    },

    async handleGetCode() {
      if (!this.phone) {
        this.$notify({
          title: '请填写手机号',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!ruleReg.test(this.phone)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }

      const res = await sendSms({
        mobile: this.phone,
      });
      if (res?.code === 0) {
        this.$notify({
          title: '验证码获取成功',
          position: 'top-right',
          type: 'warning',
        });
        this.smsUuid = res.data.smsUuid;
        this.handleGetSms();
      } else if (res?.code === -1) {
        this.$notify({
          title: '获取验证码次数超出今日限制，请明天再试',
          position: 'top-right',
          type: 'warning',
        });
      }
    },

    async handleCommit() {
      if (!this.phone) {
        this.$notify({
          title: '请填写手机号',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!ruleReg.test(this.phone)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      if (!this.code) {
        this.$notify({
          title: '请填写验证码',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      if (!this.msgVal) {
        this.$notify({
          title: '请填写留言',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      if (!this.smsUuid) {
        this.$notify({
          title: '请先获取验证码',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      const res = await commit({
        mobile: this.phone,
        smsUuid: this.smsUuid,
        smsCode: this.code,
        leaveMessage: this.msgVal,
      });
      if (res && res.status === 200) {
        this.msgVal = '';
        this.phone = '';
        this.code = '';
      }
      if (res?.message || res?.msg) {
        this.$notify({
          title: res?.message || res?.msg,
          position: 'top-right',
          type: 'warning',
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>

>>>.el-dialog__header {
  padding: 0;
}

>>>.el-input__inner {
}



>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #416DF9 !important;
}


>>>.el-carousel__indicator--horizontal {
  padding: 40px 4px;
}

.carousel-itemss {
  width: 100%;
  height: 436px;
  background: url('../assets/mpz2.png') center center / 100% 436px no-repeat;
  background-size: cover;
  &__content {
    width: 100%;
    margin: 0 auto;
    padding-top: 146px;
    font-family: PingFangSC-Regular, PingFang SC;
  }



}

.carousel-items {
  width: 100%;
  height: 436px;
  background: url('../assets/b.png') center no-repeat;
  background-size cover
  font-family: PingFangSC-Regular, PingFang SC;

  &__content {
    width: 100%;
    margin: 0 auto;
    padding-top: 114px;
  }


}

.carousel-item {
  width: 100%;
  height: 436px;
  padding: 0 100px;
  box-sizing: border-box;
  background: url('../assets/a.png') center center no-repeat;
  background-size cover
  font-family: PingFangSC-Regular, PingFang SC;

  &__content {
    width: 100%;
    margin: 0 auto;
    padding-top: 146px;
  }


}

.page-home {
  width: 100%;
  background: #fff;
  text-align: left;

}

>>>.is-active {
  >>>.el-carousel__button {
    width: 44px;
    height: 4px;
    background: #416DF9 !important;
    border-radius: 3px;
  }
}




.page-home-desc{
  width: 100%;
  height: 513px;
  background: #fff;
}
.page-title{
  font-family: SourceHanSansCN, SourceHanSansCN;
font-size: 40px;
color: #1B2532;
line-height: 60px;
font-style: normal;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.page-subtitle{
  display: flex;
  justify-content: center;
  margin-top 20px
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 16px;
color: #738093;
font-style: normal;
}

.page-subdesc{
  font-weight: 400;
  font-size: 16px;
  color: #738093;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.page-home_warp{
  width: 1176px;
  height: 136px;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
}

.page-item{
  position: relative;
}

.page-item::after{
  content: '';
  position: absolute;
  width: 1px;
  height: 57px;
  background: #F2F2F2;
  right: -60px;
  top: 8px;
}

.item-top{
  font-size: 28px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.item-bottom{
  font-size: 12px;
  color: #666;
  text-align: center;
  margin-top: 10px;
}

.page-advantage{
  background: #F4F5F7;
  height: 700px;
  width: 100%;
}

.advantage-title{
  width 1200px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 40px;
  color: #1B2532;
  line-height: 40px;
  font-style: normal;
  text-align center
  margin 0 auto
  padding-top 50px
  padding-bottom 20px
}
.advantage-warp{
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 54px;
  display: flex;
  justify-content: space-between;
}

.advantage-item{
  box-sizing: border-box;
  background: #fff;
  width: 380px;
  height: 460px;
}
.advantage-top{
  margin: 0 auto;
  padding-top 64px
  width: 110px;
  height: 100px;
  img{
    width: 100%;
  }
}
.advantage-text{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 28px;
  color: #0ECB92;
  line-height: 42px;
  text-align: center;
  font-style: normal;
  margin-top 65px
}

.advantage-desc{
  margin 0 auto;
  margin-top 30px
  width 320px
  
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #738093;
  line-height: 34px;
  text-align center
  font-style: normal;
}
.advantage-desc-text{
  width 1200px;
  margin 0 auto
  text-align center
  font-weight: 400;
font-size: 16px;
color: #738093;
line-height: 26px;
font-style: normal;
}
.page-product{
  width: 100%;
  height: 640px;
  background: #fff;
  box-sizing: border-box;
}
.product-title{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 40px;
  color: #1B2532;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  padding-top 48px
  text-align center
}

.product-warp{
  margin: 0 auto;
  margin-top: 100px;
    width: 1200px;
    display: flex;
    justify-content: space-between;

}

.product-item{
  box-sizing: border-box;
  width: 580px;
  height: 380px;
  background: #F4F5F7;

}

.product-top{
  width:100% ;
  height: 150px;
  img{
    width: 100%;
  }

}

.product-subtitlte{
  font-weight: bold;
  font-size: 28px;
  color: #000000;
  line-height: 42px;
  text-align: center;
  font-style: normal;
  padding-top 63px

}

.product-desc{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 16px;
  color: #738093;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  padding-top 20px
  width 523px
margin 0 auto
}

.page-platform{
  box-sizing: border-box;
  background: #F4F5F7;
  width: 100%;
  height: 800px;

}

.platform-title{
  padding-top 48px
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 40px;
  color: #1B2532;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  padding-bottom 93px
}
.active{
  background: #0ECB92 !important
  color #fff !important
}
.platform-list{
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  border:1px solid  #DEE2E6;
  height: 78px;
  background: #F3F3F6;
}

.list-item:last-child{
  border none
}
.list-item{
  width 33.3%
  text-align center
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: bold;
font-size: 18px;
color: #1B2532;
line-height: 78px;
background: #F3F3F6;
text-align: center;
border-right 1px solid #DEE2E6;
font-style: normal;
}
.platform-center{
  width: 1200px;
  margin: 0 auto;
  height: 420px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
}

.platform-img{
  width: 510px;
  margin-left 22px
  height: 268px;
  margin-top 75px
}

.platform-desc{
  margin-left: 24px;
}

.desc-sbutitle{
  color: #333;
  font-size: 18px;
  padding-top: 59px;
  font-weight: bold;
}

.desc-text{
  width 577px
  text-align: justify;
  font-size: 16px;
color: #738093;
padding-top 137px
line-height: 30px;
}

.application-service{
  width: 100%;
  height: 700px;
  background: #fff;
}

.application-warp{
  margin: 0 auto;
  width:1200px;
  box-sizing: border-box;
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  flex-wrap: wrap;

}

.application-item{
  width: 585px;
  height: 180px;
  background: #F2F3F6;
  display: flex;
  box-sizing: border-box;
  padding: 40px;
  img{
    width: 104px;
    height: 97px;
  }
}
.application-right{
  width 360px
  margin-left: 40px;

}
.application-subtitle{
  color: #000;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  line-height: 36px;
  text-align: left;
  font-style: normal;
}

.application-desc{
  color: #666;
  font-size: 14px;

  font-weight: 400;
font-size: 16px;
color: #738093;
line-height: 24px;
text-align: justify;
font-style: normal;
margin-top 12px
}
.application-item:nth-child(3){
  margin-top: 30px;
}
.application-item:last-child{
  margin-top: 30px;
}



.us-content{
  width: 1176px;
  margin:  0 auto;
  display: flex;
  justify-content: space-between;
}

.us-titlte{
  font-size: 40PX;
  color: #333;

  font-weight: bold;
}
.us-left{
  height: 822px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.us-desc{
  color: #666;
  font-size: 16px;
  margin-top: 15px;
}


@media screen and (min-width: 1024px) {
.page-product-moblie,.platform-warps-moblie{
  display: none;
}
.platform-tab-moblie-container{
  display none
}
.page-moblie-desc,.page-bg-home-moble,.moblie-block{
  display none
}
  .page-home_warp{
    display: block;
    display: flex;
  }
  .page-service-warps{
    display: none !important;
  }
  .contact-us{
    display: block;
  }
  .platform-sj-warp{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .block{
    display: none !important;
  }
 .page-subdesc,.page-subtitle{
  display none !important
 }
 .page-bg-home{
  display none !important
 }
  .page-product{
    display none
  }
.page-moblie-desc{
  box-sizing border-box
  padding 0 14px
  font-size: 14px;
color: #738093;
text-align center
}
.page-bg-home-moble{
  width 100%
  display flex
  justify-content center
  position absolute
  bottom 0
}
.page-home-desc{
  height 338px
  position relative
}
.AAS-I{
  width: 238px;
  height: 88px;
}
  .advantage-title ,.advantage-desc-text{
    width 100%
    box-sizing border-box
    padding 0 14px
  }

  .page-advantage{
    height 575px
    padding-bottom 30px
  }
.advantage-title{
  padding-top 20px

}
  .advantage-warp{
    margin-top 30px
  }
  .sj-item-title{
    color: #333;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 58px;
  }
  .platform-sj-warp{
    display: block;
    padding-bottom: 30px;
  }
  .application-warp{
    padding 0 14px
    padding-top 50px
    margin 0
  }
  .page-platform{
    height: auto;
    padding-bottom 28px
  }
  .sj-item-tops{
    margin: 0 auto;
    width: 276PX;
    height: 206PX;
    margin-top: 15px;
    img{
      width: 100%;
    }
  }
  .sj-item-desc{
    font-size: 14px;
    margin-top: 20px;
    color: #666;
    line-height: 30px;
    padding: 0 37px;
    text-align: justify;
  }
  .contact-us{
    display: none;
  } 
  .application-service{
    height: auto;
    padding-bottom 30px
  }
 
  .page-product{
    height: auto !important;
  }
  .application-right{

    margin-left 10px
  }
  .application-subtitle{
    font-size 16px
    line-height 0
    padding-top 26px
  }
  .application-desc{
    font-size 12px
    line-height 18px
    padding-top 15px
    padding-right: 10px;
    margin-top 0

  }
  .application-item{
    height: 120px;
    padding 10px

  }
  .application-item:nth-child(2){
    margin-top: 15px;
  }
  .application-item:nth-child(3){
    margin-top: 15px;
  }
  .application-item:nth-child(4){
    margin-top: 15px;
  }
  .application-item img{
  }
  .page-service-warps{
    display: block;
    margin-top: 50px;
    padding: 0 37px;
    .warps-top{
      display: flex;
      justify-content: space-between;
    }
    .warps-top-left{
      width: 48%;
    }
    .warps-top-right{
      width: 48%;
    }
    .warps-top-left-span{
      font-size: 28PX;
      color: #333;
      font-weight: bold;
      text-align: center;
    }
    .warps-top-left-desc{
      margin-top: 10px;
      font-size: 12px;
      color: #666;
        text-align: center;
    }
  }
  .page-subtitle,.page-subdesc{
    font-size: 14px;
  }
  .conact-wx{
    box-sizing: border-box;
    height: auto;
    padding: 0 37px;
    padding-bottom: 20px;
    margin-top: 10px;
  }

  .page-home__record{
    width 1200px
    height: auto;
  }
  .title-text,.us-mailbox{
    text-align: center;
  }

  .page-home_warp,.advantage-warp,.product-warp,.application-warp,.platform-list,.platform-center，.us-content{
    width: 100% !important;
  }
 
  .platform-warp{
    display: none;
  }
  .advantage-title,.product-title,.platform-title{
    font-size: 21px;
    padding-top 28px
    padding-bottom 28px
  }
  .product-warp,.advantage-warp{
    flex-wrap: wrap;
  }
.product-title{
  padding-top 28px  !important
}
  .product-warp{
    padding:  0 37px ;
    box-sizing: border-box;
  }
  .product-item{
    width: 100%;
    padding: 0 20px;
    padding-top: 20px;
    text-align:left;
    margin-bottom: 15px;
  }
  .application-item{
    width: 100%;
  }
  .page-subtitle{
    margin-top: 40px;
  }
  .advantage{
    width: 100%;
  }

  .page-title{
    font-size: 21px;
  }

  .page-subtitle{
    padding: 0 37px;
    text-align: center;
  }


  .page-subdesc{
    padding: 0 37px;
    text-align: center;
  }

  .advantage-warp{
    padding: 0 14px;
    .advantage-item{
      width: 100%;
      height: 126px;
      margin-bottom: 10px;
      padding 10px
      position relative
     
      .advantage-sj{
        width calc(100% - 125px)
      position absolute 
      right 15px
      }  
       .advantage-top{
        width: 76px;
        height: 69px;
        margin-left 15px
        margin-top 30px
        float left
        padding 0
      }
      .advantage-text{
        font-size 16px
      }
      .advantage-text,.advantage-desc {
        margin-top: 0;
        text-align: justify;
      }
      .advantage-desc{
        width 100%
        font-size 12px
        line-height 18px
      }
    }

  }
}

.icnon-img{
  width 30px
  height 30px
  vertical-align: text-bottom;
}

.page-bg-home{
  width 1200px
  margin 0 auto
  margin-top 77px
  display flex
  justify-content: center;
}

>>>.el-carousel__indicators{
  display none !important
}

.mask-dov{
  width 100%
  position absolute
  bottom 0
  height: 100px;
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
  z-index 200
}

.block{
  position relative
}

.car-contaienr{
  width 1200px
  margin 0 auto
}

.mask-tip{
  width 1200px
  margin 0 auto
  display flex
  padding-top 15px
  justify-content space-between
}

.mask-left-img{
  padding-top 10px
  width 40px
  height 40px
  img{
    width 100%
  }
}
.mask-item{
  display flex
}

.right-top-a{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 24px;
  color: #0ECB92;
  line-height: 36px;
  text-align: left;
  font-style: normal;
}
.right-top-desc{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 14px;
  color: #2B3440;
  line-height: 21px;
  text-align: left;
  font-style: normal;
 
}
.mask-right-desc{
  margin-left 10px
}
.car-top{
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: bold;
font-size: 34px;
color: #2B3440 !important;
line-height: 51px;
text-align: left;
font-style: normal;
padding-top 72px
}
.car-top-desc{
  width: 661px;
  font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 18px;
color: #2B3440;
line-height: 27px;
text-align: left;
margin-top 20px
font-style: normal;
}

.one-button{
  margin-top 20px
  width: 200px;
height: 56px;
background: #FABE0D;
box-shadow: 0px 4px 8px 0px rgba(250,190,13,0.2);
border-radius: 8px;
cursor pointer
font-family: SourceHanSansCN, SourceHanSansCN;
font-weight: 400;
font-size: 18px !important;
color: #FFFFFF;
line-height: 56px;
text-align: center;
font-style: normal;
font-weight normal !important;
}

.page-product-moblie{
  width 100%
  min-height: 314px;
  background: #FFFFFF;
  box-sizing border-box
  padding 0 14px
  padding-bottom 30px
}
.product-title-moblie{
  font-weight: bold;
  font-size: 21px;
  color: #1B2532;
  text-align center
  margin-top 28px
}

.product-item-moblie{
  width 100%
  min-height: 104px;
  max-height:137px;
  background: #F4F5F7;
  display flex
  margin-top 20px
}

.product-top-moblie{
  width 104px
  height 100%;
  display flex;
  align-items center
  padding-top 16px
  img{
    width 104px;
height :104px;
  }
}

.product-subtitlte-moblie{
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}
.product-desc-moblie{
  margin-top 10px
  font-size: 12px;
color: #738093;
line-height :17px;
  text-align :left;
}

.product-moblie-lfet{
  padding 14px
}
.moblie-block{
  width 100%
  height 428px
  position relative
}
.page-moblie-one{
  width: 100%;
  background: url('../assets/ab31.png') center center / 100% 414px no-repeat;
  background-size: cover;
}

.page-moblie-two{
  width: 100%;
  background: url('../assets/asssf.png') center center / 100% 414px no-repeat;
  background-size: cover;
}
.page-moblie-three{
  width: 100%;
  background: url('../assets/ssfewe.png') center center / 100% 414px no-repeat;
  background-size: cover;
}

.car-contaienr-moblie{
  padding 0 14px
}

.car-top-moblie{
  width 90%;
  font-weight: bold;
  font-size: 28px;
  color: #2B3440;
  padding-top 22px
}

.car-top-desc-moblie{
  margin-top 6px
  font-size 14px
  color: #2B3440;
}

.one-button-moblie{
  width: 120px;
height: 42px;
background: #FABE0D;
box-shadow: 0px 1 3px 0px rgba(250,190,13,0.2);
border-radius: 4px;
color:#FFF;
text-align center
font-size 14px
cursor pointer
line-height 42px
margin-top 14px
}

.moblie-mask-div{
  box-sizing border-box
  position absolute
  width 100%;
  height 150px
  padding 0 14px
  bottom 0 
  z-index 25
  display flex
  flex-wrap wrap
  justify-content space-between
}


.moblie-mask-item{
  width 48%
  box-sizing border-box
  height: 60px;
  background: rgba(255,255,255,0.74);
  backdrop-filter: blur(10px);
  margin-bottom 14px
  padding 5px
  display flex
}

.mask-left-img-moblie{
  display flex
  align-items: center;
  img{
    width :24px;
    height :24px;
  }
}

.right-top-a-moblie{
  font-weight: bold;
  font-size: 14px;
  color: #0ECB92;
}

.right-top-desc-moblie{
  font-weight: 400;
  font-size: 12px;
  color: #2B3440; 
  text-align: left;
}
.platform-tab-moblie-container{
  width 100%
  box-sizing border-box
  padding 0 14px
}
.platform-tab-moblie{
  width 100%;
  background #fff
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; /* 添加这一行以防止子元素换行 */
}

.tab-items{
  display: inline-block; /* 添加这一行使子元素水平排列 */
  min-width 100px
  height 50px
  background #fff
}

.tab-items{
  font-weight: bold;
font-size: 14px;
color: #1B2532;
line-height 50px
padding 0 20px
}

.platform-tab-mobile::-webkit-scrollbar {
  display: none !important; /* For Webkit browsers (Chrome, Safari) */
}

.active-box{
  color: #0ECB92;
}

.img-mobile{
  width 100%
}

.platform-warps-moblie{
  width 100%
  box-sizing border-box
  padding 0 14px
}

.warps-moblie-ping{
  box-sizing border-box
  padding 0 10px;
  width 100%
  background #fff
  padding-bottom 20px
}

.warps-moblie-desc{
 
  font-size: 14px;
color: #738093;
text-align center
}


</style>
