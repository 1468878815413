<template>
  <el-dialog :visible.sync="dialogVisible" width="750px" :close-on-click-modal="false" :show-close="false">

    <div class="dialog-body none">
      <div class="body-title">
        <div class="title-left">
          <img :src="require('@/assets/logo @2x.png')" alt="" class="img" /><span class="ass">申请试用</span>
        </div>
        <div class="title-right"><i class="el-icon-close" @click="iconClose"></i></div>
      </div>
      <div class="body-form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
          <el-form-item label="" prop="name">
            <el-input v-model="ruleForm.name" maxlength="10" placeholder='您的姓名'></el-input>
          </el-form-item>
          <el-form-item label="" prop="companyName">
            <el-input v-model="ruleForm.companyName" maxlength="30" placeholder="企业名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="mobile">
            <el-input v-model="ruleForm.mobile" placeholder="手机号码" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="" prop="smsCode">
            <div class="get-div">
              <el-input v-model="ruleForm.smsCode" placeholder="手机验证码"></el-input>
              <el-button @click="handleSendSms" class="a-btn" :disabled="smsBtnDisabled">{{ smsCodeText }}
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="" prop="email">
            <el-input v-model="ruleForm.email" placeholder="公司邮箱"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="value" multiple placeholder="您希望了解" style="width: 100%">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input type="textarea" placeholder="留言" v-model="ruleForm.leaveMessage" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="submit" :disabled="loginDisWeb" @click="submitMessage">提交</el-button>
          </el-form-item>
        </el-form>
        <div class="moblie-desc-tip">
          <span style="font-size: 12px;color: #000000;"> 已阅读并同意</span>
          <span style="font-size: 12px;color: #FABE0D;cursor: pointer;margin-left:10px"
            @click='opens'>OneESG用户协议与隐私条款</span>
        </div>
      </div>
    </div>
    <div class="dialog-body blok-moblie">
      <div class="body-title">
        <div class="title-left">
          <img :src="require('@/assets/logo @2x.png')" alt="" class="img" /><span class="ass">申请试用</span>
        </div>
        <div class="title-right"><i class="el-icon-close" @click="iconClose"></i></div>
      </div>
      <div class="body-form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="0px" class="demo-ruleForm">
          <el-form-item label="" prop="name">
            <div class="lable-molbie">您的姓名 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.name" maxlength="10" placeholder='您的姓名'></el-input>
          </el-form-item>
          <el-form-item label="" prop="companyName">
            <div class="lable-molbie">企业名称 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.companyName" maxlength="30" placeholder="企业名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="mobile">
            <div class="lable-molbie">手机号码 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.mobile" placeholder="手机号码" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="" prop="smsCode">
            <div class="lable-molbie">手机验证码 <span style='color:red'>*</span></div>
            <div class="get-div">

              <el-input v-model="ruleForm.smsCode" placeholder="手机验证码"></el-input>
              <el-button @click="handleSendSms" class="a-btn" :disabled="smsBtnDisabled">{{ smsCodeText }}
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="" prop="email">
            <div class="lable-molbie">公司邮箱 <span style='color:red'>*</span></div>
            <el-input v-model="ruleForm.email" placeholder="公司邮箱"></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="lable-molbie">您希望了解</div>
            <el-select collapse-tags v-model="value" multiple placeholder="您希望了解" style="width: 100%">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <div class="lable-molbie">留言</div>
            <el-input type="textarea" placeholder="留言" v-model="ruleForm.leaveMessage" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="submit" :disabled="loginDisH5" @click="submitMessage1">提交</el-button>
          </el-form-item>
        </el-form>
        <div class="moblie-desc-tip">
          <span style="font-size: 12px;color: #000000;"> 已阅读并同意</span>
          <span style="font-size: 12px;color: #FABE0D;cursor: pointer;margin-left:10px"
            @click='opens'>OneESG用户协议与隐私条款</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { commit, sendSms } from '../request/fetch.js';
export default {
  name: 'ApplyPage',
  props: {},
  data() {
    var namePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('姓名不能为空'));
      } else {
        callback();
      }
    }
    var companyNamePass = (rule, value, callback) => {
      if (value == '') {
        console.log('ASDASD')
        callback(new Error('公司名称不能为空'));
      } else {
        callback();
      }
    }
    var phonePass = (rule, value, callback) => {

      if (value == '') {
        callback(new Error('手机号码不能为空'));
        return
      }
      const ruleReg = /^\d{11}$/;
      if (!ruleReg.test(value)) {
        callback(new Error('手机号码格式不正确'));
        return
      }
      callback();
    }
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('公司邮箱不能为空'));
        return
      }
      var reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!reg.test(value)) {
        callback(new Error('输入内容必须为邮箱格式'));
      } else {
        callback();
      }
    };
    return {
      loginDisWeb: false,
      loginDisH5: false,
      smsBtnDisabled: false,
      ruleForm: {
        name: '',
        companyName: '',
        mobile: '',
        smsCode: '',
        email: '',
        hopeToLearnMore: '',
        leaveMessage: '',
        uuid: '',
      },
      value: [],
      smsNum: 60,
      options: [
        {
          value: 'OneESG价值核算数据',
          label: 'OneESG价值核算数据',
        },
        {
          value: 'OneESG底层数据',
          label: 'OneESG底层数据',
        },
        {
          value: 'OneESG价值核算数据平台',
          label: 'OneESG价值核算数据平台',
        },
        {
          value: 'OneESG碳智平台',
          label: 'OneESG碳智平台',
        },
        {
          value: 'OneESG智能数据披露平台',
          label: 'OneESG智能数据披露平台',
        },
      ],
      rules: {
        name: [{ required: true, min: 1, max: 10, validator: namePass }],
        companyName: [{ required: true, message: '输入内容必须为30个字符以内，包括中英文和符号', min: 1, max: 30, validator: companyNamePass }],
        mobile: [{ required: true, message: '输入内容必须为11位手机号格式', min: 11, max: 11, validator: phonePass }],
        email: [{ required: true, validator: validatePass }],
        smsCode: [{ required: true, message: '请输入手机验证码' }],
      },
      dialogVisible: true,

    };
  },
  computed: {
    smsCodeText: function () {
      return this.smsNum === 60 ? `获取验证码` : `${this.smsNum}s后重新发送`;
    },
  },
  async mounted() {
    var windowHeight = window.innerHeight;
    setTimeout(() => {
      this.$nextTick(() => {
        document.querySelector('.blok-moblie').style.setProperty('height', windowHeight + 'px', 'important');
        console.log()
      })

    }, 100);

  },

  methods: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    opens() {
      let origin = window.origin
      window.open(origin + '/agreement/OneESG用户协议与隐私条款.pdf')
    },
    async submitMessage1() {
      this.loginDisH5 = true
      this.$refs['ruleForm1'].validate(async (valid) => {
        if (valid) {

          let str = this.value.join();
          this.ruleForm.hopeToLearnMore = str;
          let res = await commit(this.ruleForm);
          if (res.code == 0) {
            this.ruleForm = {
              name: '',
              companyName: '',
              mobile: '',
              smsCode: '',
              email: '',
              hopeToLearnMore: '',
              leaveMessage: '',
              smsUuid: '',
            };
            this.value = [];
            this.$refs['ruleForm'].resetFields();
            this.iconClose()
            this.$notify({
              title: '已提交试用申请，我们将尽快与您联系',
              position: 'top-right',
              type: 'success',
            });
            this.loginDisH5 = false
          } else {
            if (res?.message || res?.msg) {
              this.$notify({
                title: res?.message || res?.msg,
                position: 'top-right',
                type: 'warning',
              });
            }
            this.loginDisH5 = false
          }

        } else {
          this.loginDisH5 = false
          console.log('error submit!!');
          return false;
        }
      });
    },
    async submitMessage() {
      this.loginDisWeb = true
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let str = this.value.join();
          this.ruleForm.hopeToLearnMore = str;
          let res = await commit(this.ruleForm);
          if (res.code == 0) {
            this.ruleForm = {
              name: '',
              companyName: '',
              mobile: '',
              smsCode: '',
              email: '',
              hopeToLearnMore: '',
              leaveMessage: '',
              smsUuid: '',
            };
            this.value = [];
            this.$refs['ruleForm'].resetFields();
            this.iconClose()
            this.$notify({
              title: '已提交试用申请，我们将尽快与您联系',
              position: 'top-right',
              type: 'success',
            });
            this.loginDisWeb = false
          } else {
            if (res?.message || res?.msg) {
              this.$notify({
                title: res?.message || res?.msg,
                position: 'top-right',
                type: 'warning',
              });
            }
            this.loginDisWeb = false
          }

        } else {
          this.loginDisWeb = false
          console.log('error submit!!');
          return false;
        }
      });
    },
    async handleSendSms() {
      const ruleReg = /^\d{11}$/;
      console.log(this.ruleForm, 'sdaasdasd')
      if (!ruleReg.test(this.ruleForm.mobile)) {
        this.$notify({
          title: '手机号格式不正确',
          position: 'top-right',
          type: 'warning',
        });
        return;
      }
      let res = await sendSms({ mobile: this.ruleForm.mobile });
      if (res?.code === 0) {
        this.$notify({
          title: '验证码获取成功',
          position: 'top-right',
          type: 'warning',
        });
        this.ruleForm.smsUuid = res.data.smsUuid;
        this.handleGetSms();
      } else if (res?.code === -1) {
        this.$notify({
          title: '获取验证码次数超出今日限制，请明天再试',
          position: 'top-right',
          type: 'warning',
        });
      }
    },
    handleGetSms: async function () {
      const ruleReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (ruleReg.test(this.ruleForm.mobile) && !this.smsBtnDisabled) {
        this.smsBtnDisabled = true;
        let timer = setInterval(() => {
          --this.smsNum;
        }, 1000);
        setInterval(() => {
          clearInterval(timer);
          this.smsNum = 60;
          this.smsBtnDisabled = false;
        }, 60000);
      }
    },
    iconClose() {
      this.$parent.applyPageState = false;
    },
  },
};
</script>

<style scoped lang="stylus">
>>>.el-button--primary:hover,>>>.el-button--primary:focus{
  background #f5ba0d
  border-color #f5ba0d
}
>>>.el-button--primary:active{
  background #f5ba0d
  border-color #f5ba0d
}
>>>.el-button--primary:focus{
  background #f5ba0d
  border-color #f5ba0d
}
.a-btn:hover{
  background #f5ba0d
  border-color #f5ba0d
  color:#fff
}
>>>.el-dialog__header{
  display: none;
}

>>>.el-dialog__body{
  width: 100%;
  min-height: 500px;
  max-height:880px;
  padding: 0;
}

.body-form{
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.body-title{
  display: flex;
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}
.img{
  width: 180px;
  height: 65px;
}
.title-left{
  font-size: 21px;
  color: #333;
  font-weight: bold;
}

.el-icon-close{
  font-size: 28px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.submit{
  width: 100%;
  background: #F5BA0D;
  border-color: #F5BA0D;
}
.ass{
  margin-left: 12px;
}
@media only screen and (max-width: 768px) {
  >>>.el-dialog__body{
  width: 100%;
  display: block; /* 确保 display 属性适合你的布局 */
  overflow hidden
  height 100vh
  padding: 0;
  .dialog-body{
    height calc(100vh - 100px)
    overflow-y scroll
    -webkit-overflow-scrolling: touch;
  }
}

>>>.el-form-item {
  margin-bottom: 6px;
}
.none{
  display none !important
}
>>>.el-dialog{
  margin-top: 0 !important;
  width: 100% !important;
  height: 100%;
  margin:  0 !important;
  overflow: auto;
}
>>>.el-dialog__wrapper{
  overflow: hidden !important;

}

.img{
  width: 90px;
  height: 25px;
}
.ass{
  font-size: 14px;
  margin-left: 12px;
}
.title-left{
  margin-left: 10px;
}
.el-icon-close{
  font-size: 18px;
}
.body-form{
  margin-top: 40px;
}
.dialog-body{
  overflow: auto;
  padding-bottom: 10px;
}
}

>>>.el-input__inner::placeholder{
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-size: 16px !important
  color: #1B2532 !important
  line-height: 24px !important
  text-align: left !important
}
>>>.el-input__inner{
  font-family: SourceHanSansCN, SourceHanSansCN  !important
  font-size: 16px !important
  color: #1B2532 !important
height 50px
}
>>>.el-textarea__inner::placeholder{
  font-size: 16px !important
      font-family: SourceHanSansCN, SourceHanSansCN;

  color: #1B2532 !important
}
>>>.el-textarea__inner{
  font-size: 16px !important
      font-family: SourceHanSansCN, SourceHanSansCN  !important
  color: #1B2532 !important
}
.get-div{
  position relative
}
.a-btn{
  position absolute
  right  6px
  top 6px
  width: 120px;
  height: 40px;
  background: #FABE0D;
  border-radius: 6px;
  border:none
  font-weight: 500;
font-size: 16px;
color: #FFFFFF;
  display flex
  align-items center
  justify-content center
}

@media screen and (min-width: 1024px) {
  .blok-moblie{
 display: none;
}

}
.lable-molbie{
  color #000
  font-family: SourceHanSansCN, SourceHanSansCN;
}
</style>
