<template>
  <div id="app">
    <!-- <div style="margin: 0 auto; width: 1400px;"> -->
    <Header @headerTabChange="handleTabChange" />
    <!-- <router-view /> -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <!-- </div> -->

    <ApplyPage v-if="applyPageState" ref="apply" key="sadasd"></ApplyPage>

  </div>
</template>

<script>
import Header from './components/header.vue';
// import AboutPage from './components/about.vue'
import ApplyPage from './components/apply.vue';
// import EsgPage from './components/esg.vue'
// import HomePage from './components/home.vue'
export default {
  name: 'App',
  components: {
    Header,
    ApplyPage
    // AboutPage,
    // EsgPage,
    // HomePage
  },
  data() {
    return {
      applyPageState: false,
      curTab: 1,
    };
  },
  watch: {
    applyPageState() {
      // if (val) {
      //   document.getElementById('app').style.overflow = 'hidden';
      // } else {
      //   document.getElementById('app').style.overflow = 'auto';
      // }
    }
  }
  ,

  mounted() {
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );

    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );

    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });


  },
  methods: {
    handleTabChange(val) {
      if (val === 5) {
        window.open('https://app.oneesg.cn');
        return;
      } else {
        const routerUrl = val === 1 ? 'home' : val === 3 ? 'esg' : 'about';
        this.$router?.push(routerUrl);
        this.curTab = val;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
</style>
